import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SC000_Event, SC000_Municipalities } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { CONST_PAGE_URL } from 'common/CM000_Const'
import { cm010_getImageUrl } from 'common/CM010_AppUtil'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'

interface SC130_C01_SearchResInfoProps {
  eventList: SC000_Event[];
}

export const SC130_C01_SearchResInfo: React.FC<SC130_C01_SearchResInfoProps> = ({ eventList }) => {
  const navigate = useNavigate();
  const { state: baseState } = useContext(SC000_S_Context);
  const genreList = baseState.genreList;

  // 東京都の市区町村リストを取得
  const getMunicipalitiesList = () => {
    const prefecture = baseState.prefecturesList.find(
      pref => pref.prefecturesId === '13_TOKYO'
    );
    return prefecture ? prefecture.municipalitiesList : [];
  };

  const tokyoMunicipalitiesList = getMunicipalitiesList();

  // 市区町村IDから区名を取得する関数
  const getMunicipalityName = (municipalityId: string) => {
    const municipality = tokyoMunicipalitiesList.find(
      m => m.municipalitiesId === municipalityId
    );
    return municipality ? municipality.municipalitiesName : '';
  };

  // ジャンルIDから日本語名を取得する関数
  const getGenreName = (genreId: string) => {
    const genre = genreList.find(g => g.genreId === genreId);
    return genre ? genre.genreName : genreId;
  };

  // YYYYMMDD 形式の文字列を YYYY/MM/DD 形式に変換する関数
  const formatDate = (dateString: string) => {
    if (!dateString || dateString.length !== 8) return '';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${year}/${month}/${day}`;
  };

  // イベントクリック時のイベントハンドラ関数
  const handleEventClick = (eventId: string) => {
    navigate(CONST_PAGE_URL.SC140_C00_SearchResultDetail + `/${eventId}`);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="flex-grow">
        <div className="px-4 py-2 text-sm text-gray-500">
          検索結果：{eventList.length}件
        </div>

        <div className="divide-y">
          {eventList.map((event: SC000_Event) => {
            const municipalityName = getMunicipalityName(event.municipalitiesId);
            const startDate = formatDate(event.eventStrDate);
            const endDate = formatDate(event.eventEndDate);

            return (
              <div
                key={event.eventId}
                className="p-4 cursor-pointer hover:bg-gray-50 transition-colors duration-150"
                onClick={() => handleEventClick(event.eventId)}
              >
                <div className="flex">
                  <img
                    src={cm010_getImageUrl(event.eventImageUrl, event.eventImageOriginUrl)}
                    alt={event.eventName}
                    className="w-24 h-24 object-cover rounded"
                  />
                  <div className="ml-4 flex-1">
                    <h2 className="text-sm sm:text-lg font-semibold">{event.eventName}</h2>

                    {/* タグエリア */}
                    <div className="flex flex-wrap gap-1.5 mt-1.5 items-center">
                      {/* 区のタグ */}
                      {municipalityName && (
                        <span className="px-2 py-0.5 text-xs bg-blue-25 text-gray-600 rounded">
                          {municipalityName}
                        </span>
                      )}

                      {/* ジャンルタグ */}
                      {event.eventGenreIdList && event.eventGenreIdList.map((genreId, index) => (
                        <span
                          key={index}
                          className="px-2 py-0.5 text-xs bg-gray-25 text-gray-600 rounded"
                        >
                          {getGenreName(genreId)}
                        </span>
                      ))}
                    </div>

                    {/* イベント期間 */}
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        開催日：{startDate} ～ {endDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};