import React from 'react';
import { Link } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const'

export const SC110_C99_Footer = () => {
  return (
    <footer className="bg-white py-8 border-t">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center space-y-4">
          {/* ナビゲーションリンク */}
          <div className="flex justify-center space-x-4 md:space-x-8 mb-2">
            <Link
              to={CONST_PAGE_URL.SC100_C00_LandingPage}
              className="text-xs md:text-base text-gray-600 hover:text-pink-500"
            >
              Publiyとは
            </Link>
            <Link
              to={CONST_PAGE_URL.SC100_C01_Terms}
              className="text-xs md:text-base text-gray-600 hover:text-pink-500"
            >
              利用規約
            </Link>
            <Link
              to={CONST_PAGE_URL.SC100_C02_Contact}
              className="text-xs md:text-base text-gray-600 hover:text-pink-500"
            >
              お問い合わせ
            </Link>
            <Link
              to=""
              className="text-xs md:text-base text-gray-600 hover:text-pink-500"
            >
              About us
            </Link>            
          </div>

          {/* コピーライト */}
          <div className="text-xs text-gray-500">
            © {new Date().getFullYear()} Publiy All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};