import axios from 'axios';
import { CONST_REQ } from 'common/CM000_Const'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'

const COMMON_ID = 'CM070'

// Postメソッド
export const requestPost = async (url: string, requestData: object) => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(COMMON_ID, 'requestPost', [url, JSON.stringify(requestData)])
    // ----------------------------------------------------------------------
    const response = await axios.post(url, requestData, CONST_REQ.CONFIG)
    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(COMMON_ID, 'requestPost')
    // ----------------------------------------------------------------------
    return response
}

// Postメソッド(マルチコンテンツ)
export const requestPostMulti = async (url: string, requestData: object) => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(COMMON_ID, 'requestPost', [url, JSON.stringify(requestData)])
    // ----------------------------------------------------------------------
    const response = await axios.post(url, requestData, CONST_REQ.CONFIG_MULTI)
    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(COMMON_ID, 'requestPost')
    // ----------------------------------------------------------------------
    return response
}