import React from 'react';

interface LoadingSpinnerProps {
  size?: number;
  dotCount?: number;
  color?: string;
  fullScreen?: boolean;
  className?: string;
}

export const SC000_C02_LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 8,  // サイズを小さく調整
  dotCount = 3,
  color = 'bg-blue-500',
  fullScreen = false,
  className = '',
}) => {
  // ドットを生成する関数
  const renderDots = () => {
    return Array.from({ length: dotCount }).map((_, index) => (
      <div
        key={index}
        style={{ 
          width: size,
          height: size,
          animationDelay: `${index * 0.15}s`
        }}
        className={`rounded-full ${color} animate-bounce`}
      />
    ));
  };

  // 基本のスピナーコンポーネント
  const spinner = (
    <div className={`flex items-center justify-center space-x-1.5 ${className}`}>  {/* space-xを調整 */}
      {renderDots()}
    </div>
  );

  // fullScreen が true の場合、画面全体に表示
  if (fullScreen) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
        {spinner}
      </div>
    );
  }

  return spinner;
};