// 0.環境変数
// =========================================================================================================
export const CONST_ENV = {
    ENVIRONMENT_KBN: "00",
    LOG_LEVEL: 10
}

// 1.共通定数
// =========================================================================================================
export const CONST_COMMON = {
    // 環境変数
    CONF: {
        ENVIRONMENT_KBN: {
            DEV: "00",
            TEST: "20",
            PRODUCT: "40",
        }
    },
    // ログ関連定数
    LOG: {
        PROCESS_KBN: {
            START: "START",
            END: "END"
        },
        LOG_LEVEL: {
            DEBUG: 10,
            INFO: 20,
            ALERT: 30,
            ERROR: 40,
            CRITICAL: 50,
        },
        STR_END_LOG_LEVEL: 10
    }
}

// react-router-domの遷移先URL
export const CONST_PAGE_URL = {
    SC100_C00_LandingPage: '/landingPage',
    SC100_C01_Terms: '/terms',
    SC100_C02_Contact: '/contact',
    SC110_C00_Home: '/home',
    SC130_C00_SearchResult: '/searchResult',
    SC140_C00_SearchResultDetail: '/searchResultDetail',
    SC200_C00_ArticleList: '/articleList',
    SC210_C00_ArticleDetail: '/articleDetail',
    SC220_C00_ArticleCreate: '/articleCreate',
}

// リクエスト関連の定数
export const CONST_REQ = {
    CONFIG: {
        // RequestHeader
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Headers': '*',
        },
    },
    CONFIG_MULTI: {
        // RequestHeader
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Headers': '*',
        },
    }
}

// SERVICE_IDをキーとした、APIのURL情報
export const CONST_SERVICE_URI = {
    SR0130: 'https://api0130-select-event-detail-info-skkm2kdvaq-uc.a.run.app',
    SR0140: 'https://api0140-select-event-list-skkm2kdvaq-uc.a.run.app',
    SR0190: 'https://api0190-select-prefectures-list-skkm2kdvaq-uc.a.run.app',
    SR0240: 'https://api0240-select-genre-list-skkm2kdvaq-uc.a.run.app',
    SR0290: 'https://api0290-select-master-handler-list-skkm2kdvaq-uc.a.run.app',
    SR0310: 'https://api0310-create-article-skkm2kdvaq-uc.a.run.app',
    SR0320: 'https://api0320-update-article-skkm2kdvaq-uc.a.run.app',
    SR0321: 'https://api0321-update-article-dl-url-skkm2kdvaq-uc.a.run.app',
    SR0330: 'https://api0330-select-article-detail-info-skkm2kdvaq-uc.a.run.app',
    SR0340: 'https://api0340-select-article-list-skkm2kdvaq-uc.a.run.app',
}
// サービス処理結果
export const CONST_SERVICE_RESULT_STATUS = {
    SUCCESS: 'SUCCESS',
    BUSINESS_ERROR: 'BUSINESS_ERROR',
    SYSTEM_ERROR: 'SYSTEM_ERROR',
}

// 2.画面のみで使う定数
// =========================================================================================================
// SC000---------------------------------------------------------------------------
export const CONST_SC000 = {
    // 画面ID
    // SCREENID: {
    //     SC010: "SC010",
    //     SC020: "SC020",
    //     SC030: "SC030",
    //     SC110: "SC110",
    //     SC120: "SC120",
    //     SC210: "SC210",
    //     SC220: "SC220",
    //     SC310: "SC310",
    //     SC320: "SC320",
    //     SC410: "SC420",
    //     SC430: "SC430",
    //     SC950: "SC950",
    //     SC998: "SC998",
    //     SC999: "SC999",
    //     SC999_V04: "SC999_V04",
    //     SC999_V21: "SC999_V21",
    //     SC999_V30: "SC999_V30",
    // },

    SCREENINFO: {
        SC010: { SCREENID: "SC010", HEADERKBN: "0", FOOTERKBN: "0" }, // HEADERKBN/FOOTERKBN："0"（非表示）、"1"（表示）
        SC020: { SCREENID: "SC020", HEADERKBN: "0", FOOTERKBN: "0" },
        SC030: { SCREENID: "SC030", HEADERKBN: "0", FOOTERKBN: "0" },
        SC110: { SCREENID: "SC110", HEADERKBN: "0", FOOTERKBN: "1" },
        SC120: { SCREENID: "SC120", HEADERKBN: "0", FOOTERKBN: "1" },
        SC210: { SCREENID: "SC210", HEADERKBN: "0", FOOTERKBN: "1" },
        SC220: { SCREENID: "SC220", HEADERKBN: "0", FOOTERKBN: "0" },
        SC310: { SCREENID: "SC310", HEADERKBN: "0", FOOTERKBN: "1" },
        SC320: { SCREENID: "SC320", HEADERKBN: "0", FOOTERKBN: "1" },
        SC410: { SCREENID: "SC420", HEADERKBN: "0", FOOTERKBN: "1" },
        SC430: { SCREENID: "SC430", HEADERKBN: "0", FOOTERKBN: "1" },
        SC950: { SCREENID: "SC950", HEADERKBN: "0", FOOTERKBN: "1" },
        SC998: { SCREENID: "SC998", HEADERKBN: "0", FOOTERKBN: "1" },
        SC999: { SCREENID: "SC999", HEADERKBN: "0", FOOTERKBN: "1" },
        SC999_V04: { SCREENID: "SC999_V04", HEADERKBN: "0", FOOTERKBN: "1" },
        SC999_V21: { SCREENID: "SC999_V21", HEADERKBN: "0", FOOTERKBN: "1" },
        SC999_V30: { SCREENID: "SC999_V30", HEADERKBN: "0", FOOTERKBN: "1" },
    },
}
// SC030---------------------------------------------------------------------------
export const CONST_SC030 = {
    DefaultProfileImagePath: "010_Default/010_UaasDefaultProfile.png"
}
// --------------------------------------------------------------------------------
// SC110---------------------------------------------------------------------------
export const CONST_SC110 = {
    // コンポーネントID
    COMPONENT_ID: {
        V03: "V03",
        V04: "V04",
    },
    // 1行に何ボックス入れるかの値
    MAXROW: 10,
    MAXCOL: 2,
}
// --------------------------------------------------------------------------------
// SC210---------------------------------------------------------------------------
export const CONST_SC210 = {
    COMPONENT_ID: {
        V03: "V03",
        V04: "V04",
    }
}
// --------------------------------------------------------------------------------
// SC220---------------------------------------------------------------------------
export const CONST_SC220 = {
    LIMITNO: 50
}
// --------------------------------------------------------------------------------
// =========================================================================================================


// 3.サービスまたは画面で使う定数
// =========================================================================================================
export const C000_FIREBASE_INFO = {
    DocIdMaker: "_,_",
}
// =========================================================================================================
