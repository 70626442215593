import React from 'react';
import logo from './logo.svg';
import './App.css';
import { SC000_C00_BaseComponent } from 'screens/SC000_BaseComponent/SC000_C00_BaseComponent';
import { cm060_strLog, cm060_endLog } from 'common/CM060_LogUtil';
import { Layout } from './Layout';

function App() {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog('App', 'App')
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  // 終了ログ
  cm060_endLog('App', 'App')
  // ----------------------------------------------------------------------
  return (
    <>
      <div className="App">
        {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      </div>
      <Layout>
        <SC000_C00_BaseComponent />
      </Layout>
      
    </>
  );
}

export default App;
