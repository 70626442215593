import React, { useEffect } from 'react';
import { SC110_C99_Footer } from '../SC110_Home/SC110_C99_Footer';
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header';

export const SC100_C01_Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <SC110_C01_Header />

      <main className="container mx-auto px-4 py-8 md:py-12">
        <h1 className="text-2xl md:text-3xl font-bold mb-8 text-center">利用規約</h1>
        
        <div className="max-w-2xl mx-auto space-y-8">
          <section>
            <h2 className="text-xl font-bold mb-4">第1条（適用）</h2>
            <p className="text-gray-700 leading-relaxed">
              本規約は、当社が提供する自治体イベント情報プラットフォーム「Publiy」（以下「本サービス」といいます）の利用に関する条件を、本サービスを利用するすべての方（以下「利用者」といいます）に対して定めるものです。利用者は、本規約に同意のうえ、本サービスを利用するものとします。
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第2条（サービスの内容）</h2>
            <p className="text-gray-700 leading-relaxed">
              1. 本サービスは、東京23区の自治体が提供するイベント情報を収集し、一元的に閲覧できるプラットフォームです。<br />
              2. 本サービスは、各自治体が公開している情報を収集・整理して提供するものであり、イベントの主催者は各自治体となります。<br />
              3. 本サービスの利用にあたって、ユーザー登録やログインは不要です。
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第3条（情報の正確性）</h2>
            <p className="text-gray-700 leading-relaxed">
              1. 当社は、本サービスで提供する情報の正確性、最新性、有用性等について、可能な限り正確な情報を提供するよう努めますが、その完全性を保証するものではありません。<br />
              2. イベントの詳細情報、開催状況等については、必ず各自治体の公式情報をご確認ください。<br />
              3. 天候、災害、その他の事由によりイベントが中止・変更となる場合がありますので、参加前に主催自治体に確認することを推奨します。
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第4条（情報の利用について）</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              1. 情報の一般利用<br />
              （1）本サービスで提供される情報は、個人での利用、共有を自由に行っていただけます。<br />
              （2）SNSなどでの情報発信・共有は自由に行っていただけます。<br />
              （3）情報を共有される際は、本サービスまたは各自治体の公式情報を参照元として記載いただけますと幸いです。
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              2. プログラムによる情報収集（スクレイピング等）について<br />
              （1）プログラムを使用した情報収集（スクレイピング、クローリング等）を行う場合は、事前に当社への確認が必要です。<br />
              （2）確認なく行われたプログラムによる情報収集は、システムの安定性を損なう可能性があるため、禁止とします。<br />
              （3）プログラムによる情報収集の確認申請は、本サービスのお問い合わせフォームより行ってください。
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第5条（禁止事項）</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              利用者は、本サービスの利用にあたり、以下の行為を行ってはなりません。
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>当社への事前確認なく行うプログラムでの情報収集行為</li>
              <li>本サービスの情報を改ざんして掲載・共有する行為</li>
              <li>本サービスのシステムやネットワークに過度な負荷をかける行為</li>
              <li>本サービスの運営を妨害する行為</li>
              <li>他の利用者や第三者に不利益を与える行為</li>
              <li>本サービスの情報を商用目的で組織的に収集・利用する行為（当社との契約がある場合を除く）</li>
              <li>法令または公序良俗に反する行為</li>
              <li>その他、当社が不適切と判断する行為</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第6条（免責事項）</h2>
            <p className="text-gray-700 leading-relaxed">
              1. 情報の誤りに関する免責<br />
              （1）当社は、本サービスで提供する情報の正確性、完全性、最新性について一切保証しません。<br />
              （2）本サービスに掲載される情報に誤り、誤記、記載漏れ等があった場合でも、当社は一切の責任を負いません。<br />
              （3）情報の誤りにより利用者に損害が生じた場合でも、当社は一切の補償を行いません。<br />
              
              2. イベントに関する免責<br />
              （1）イベントの参加に関する契約は利用者と各自治体との間で成立するものであり、当社は一切関与せず、責任を負いません。<br />
              （2）イベントの中止、変更、延期等により利用者に損害が生じた場合でも、当社は一切の責任を負いません。<br />
              
              3. システムに関する免責<br />
              （1）本サービスの内容は、予告なく変更または中止される場合があります。<br />
              （2）システムメンテナンス、通信障害、その他の理由により、本サービスが一時的または永続的に停止する場合があります。<br />
              （3）本サービスの停止、障害等により利用者に損害が生じた場合でも、当社は一切の責任を負いません。<br />
              
              4. その他の免責事項<br />
              （1）本サービスの利用により発生したいかなる損害についても、当社は一切の責任を負いません。<br />
              （2）利用者と第三者との間で紛争が生じた場合、利用者は自己の責任と費用において解決するものとし、当社は一切関与いたしません。<br />
              （3）本サービスに掲載されている情報の利用について、利用者は自己の判断と責任において行うものとします。
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第7条（知的財産権）</h2>
            <p className="text-gray-700 leading-relaxed">
              本サービスに関する知的財産権は、当社または正当な権利を有する第三者に帰属します。本規約は、利用者に対して本サービスに関する知的財産権を譲渡するものではありません。
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-4">第8条（規約の変更）</h2>
            <p className="text-gray-700 leading-relaxed">
              当社は、必要と判断した場合には、利用者に通知することなく本規約を変更することができるものとします。変更後の利用規約は、本サービス上に表示した時点で効力を生じるものとします。
            </p>
          </section>

          <section className="pb-8">
            <h2 className="text-xl font-bold mb-4">第9条（お問い合わせ）</h2>
            <p className="text-gray-700 leading-relaxed">
              本規約に関するお問い合わせは、本サービスのお問い合わせフォームよりご連絡ください。
            </p>
          </section>
        </div>
      </main>

      <SC110_C99_Footer />
    </div>
  );
};