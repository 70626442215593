// [参考サイト]
// https://dexie.org/docs/Table/Table.put()
// https://dexie.org/docs/Table/Table.add()
// https://dexie.org/docs/Table/Table.update()
// https://dexie.org/docs/Table/Table.get()
// https://dexie.org/docs/Table/Table.toArray()
// https://dexie.org/docs/Collection/Collection.modify()
// https://dexie.org/docs/Dexie/Dexie.transaction()


import Dexie, { Table } from 'dexie'
import { SC000_Prefectures, SC000_Genre, SC000_IndexedDbHandler } from 'screens/SC000_BaseComponent/SC000_000_Types';

// const db = new Dexie('AppDatabase') as Dexie & {
//     SC000_Prefectures: EntityTable<SC000_Prefectures, 'prefecturesId'>
// };

export class SubClassedDexie extends Dexie {
    // テーブルの型を定義
    SC000_Prefectures!: Table<SC000_Prefectures>
    SC000_Genre!: Table<SC000_Genre>
    SC000_IndexedDbHandler!: Table<SC000_IndexedDbHandler>

    constructor() {
        // IndexDBでの名前を設定
        super('AppDatabase')

        // DBの初期化
        this.version(1).stores({
            SC000_Prefectures: '_docId, prefecturesId, prefecturesName, municipalitiesList',
            SC000_Genre: '_docId, genreId, genreName',
            SC000_IndexedDbHandler: '_docId, tableId, tableUpdDatetime',
        })
    }
}

export const IndexedDb = new SubClassedDexie()

export const iDb_ClearAndBulkAdd_SC000_Prefectures = async (prefecturesList: SC000_Prefectures[]) => {
    // IndexedDbをクリアする
    await IndexedDb.SC000_Prefectures.clear()
    //一括挿入する(Put:キー重複した場合は上書き更新し、エラーとならない)
    await IndexedDb.SC000_Prefectures.bulkAdd(prefecturesList)
    // //一括挿入する(Put:キー重複した場合は上書き更新し、エラーとならない)
    // await db.SC000_Prefectures.bulkPut(prefecturesList)
}

export const iDb_ClearAndBulkAdd_SC000_Genre = async (genreList: SC000_Genre[]) => {
    // IndexedDbをクリアする
    await IndexedDb.SC000_Genre.clear()
    //一括挿入する(Put:キー重複した場合は上書き更新し、エラーとならない)
    await IndexedDb.SC000_Genre.bulkAdd(genreList)
    // // //一括挿入する(Put:キー重複した場合は上書き更新し、エラーとならない)
    // await IndexedDb.SC000_Genre.bulkPut(genreList)
}

export const iDb_Put_SC000_IndexedDbHandler = async (iDbHandler: SC000_IndexedDbHandler) => {
    //挿入する(Put:キー重複した場合は上書き更新し、エラーとならない)
    await IndexedDb.SC000_IndexedDbHandler.put(iDbHandler)
}
export const iDb_GetALL_SC000_Prefectures = async (): Promise<SC000_Prefectures[]> => {
    // IndexedDbからデータを取得する
    const prefecturesList = await IndexedDb.SC000_Prefectures.toArray()
    // 戻り値を返却
    return prefecturesList
}

export const iDb_GetALL_SC000_Genre = async (): Promise<SC000_Genre[]> => {
    // IndexedDbからデータを取得する
    const genreList = await IndexedDb.SC000_Genre.toArray()
    // 戻り値を返却
    return genreList
}

export const iDb_GetALL_SC000_IndexedDbHandler = async (): Promise<SC000_IndexedDbHandler[]> => {
    // IndexedDbからデータを取得する
    const iDbHandlerList = await IndexedDb.SC000_IndexedDbHandler.toArray()
    // 戻り値を返却
    return iDbHandlerList
}