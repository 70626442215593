import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { getTodayYyyyMmDd } from 'common/CM050_DateUtil'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { EventTable, useEventTable } from 'screens/SC110_Home/SC110_C07_EventTable';
import { SC000_Event, SC000_SearchCondition, SC000_OrderByInfo } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { getEventList } from 'screens/SC130_SearchResult/SC130_C00_SearchResult';

const SCREEN_ID = 'SC110_C08';


export const SC110_C08_CurrentEvents = () => {
  // カスタムフックを定義
  const { eventListState, initComponent } = useEventTable(SCREEN_ID)
  // 1. 当日の日付をYYYYMMDDで取得
  const todayYyyyMmDd = getTodayYyyyMmDd();
  // 2. リクエストパラメータを取得する(...|| ''で、nullだったら空文字を設定するようにする)
  const strDate = todayYyyyMmDd
  const endDate = todayYyyyMmDd
  const municipalitiesList = [] as string[]
  const genreList = [] as string[]
  const keyword = '';
  const orderByList: SC000_OrderByInfo[] = [{ item: 'eventEndDate', direction: 'ASC', }];
  const limit = 20;

  // 初期表示処理（空の配列を渡すことで、初回レンダリング時のみ実行）
  useEffect(() => {
    // 初期化処理を実行
    initComponent(strDate, endDate, municipalitiesList, genreList, keyword, orderByList, limit);
  }, []);

  return <EventTable eventList={eventListState} title="開催中のイベント" />;
};