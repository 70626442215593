import React from 'react';

interface SC120_C04_KeywordSearchProps {
  keyword: string;
  handleKeywordForm: (keyword: string) => void;
}

export const SC120_C04_KeywordSearch: React.FC<SC120_C04_KeywordSearchProps> = ({
  keyword, handleKeywordForm
}) => {
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">キーワード検索</h3>
      <input
        type="text"
        value={keyword}
        onChange={(e) => handleKeywordForm(e.target.value)}
        placeholder="キーワード"
        className="border rounded p-2 w-full"
      />
    </div>
  );
};