import React from 'react';
import { useEffect, useState, useContext, useRef } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { CONST_SERVICE_URI, CONST_SERVICE_RESULT_STATUS } from 'common/CM000_Const'
import { cm030_stringToList } from 'common/CM030_StringUtil'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_UPDATE_SEARCH_CONDITION } from 'screens/SC000_BaseComponent/SC000_000_Action'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_Event, SC000_SearchCondition, SC000_OrderByInfo, SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { requestPost } from 'common/CM070_RequestUtil'
import { SC130_C01_SearchResInfo } from './SC130_C01_SearchResInfo';
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header'
import { SC110_C02_SearchBar } from 'screens/SC110_Home/SC110_C02_SearchBar'
import { SC000_C02_LoadingSpinner } from 'screens/SC000_BaseComponent/SC000_C02_LoadingSpinner'
import { SC110_C99_Footer } from 'screens/SC110_Home/SC110_C99_Footer';

const SCREEN_ID = 'SC130_C00'

// イベント一覧取得
export const getEventList = async (searchCondition: SC000_SearchCondition): Promise<SC000_Event[]> => {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog(SCREEN_ID, 'getEventList')
  // ----------------------------------------------------------------------
  // :TODO
  // // システムエラーチェック
  // let eventList: SC000_Event[] = []
  // // リクエストステータスが「SUCCESS」の場合、結果を戻り値に返却する
  // if (response_140.data.commonInfo.status === CONST_SERVICE_RESULT_STATUS.SUCCESS)
  //   eventList = response_140.data.result.eventList || []
  const response_140 = await requestPost(CONST_SERVICE_URI.SR0140, searchCondition)
  const eventList = response_140.data.result.eventList || []
  // ----------------------------------------------------------------------
  // 終了ログ
  cm060_endLog(SCREEN_ID, 'getEventList')
  // ----------------------------------------------------------------------
  // 戻り値を返却
  return eventList
}

export const SC130_C00_SearchResult = () => {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog(SCREEN_ID, 'SC130_C00_SearchResult')
  // ----------------------------------------------------------------------
  // 1. 各種フックを取得する
  // 1.1. コンテキストを取得する
  const { state: baseState, dispatch: baseDispatch } = useContext(SC000_S_Context)
  // 1.2. ステートを取得する
  const [eventListState, setEventListState] = useState<SC000_Event[]>([])
  const [isLoading, setIsLoading] = useState(true)  // ローディング状態追加
  const location = useLocation();
  const isInitialMount = useRef(true);
  // 1.3. リクエストパラメータを取得する
  const [searchParams, setSearchParams] = useSearchParams();


  // 即座にスクロール位置をリセットする関数
  const resetScroll = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  // 初期ロード時に実行する関数
  const initComponent = async () => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(SCREEN_ID, 'initComponent')
    // ----------------------------------------------------------------------
    setIsLoading(true)  // ローディング開始
    resetScroll();
    // 2. リクエストパラメータを取得する(...|| ''で、nullだったら空文字を設定するようにする)
    const reqStrDate = searchParams.get('strDate') || '';
    const reqEndDate = searchParams.get('endDate') || '';
    const reqMunicipalitiesList = searchParams.getAll('municipalitiesList');
    const reqGenreList = searchParams.getAll('genreList');
    const reqKeyword = searchParams.get('keyword') || '';

    // 3. 指定されたパラメータで検索する
    // リクエストパラメータ用の検索条件の作成
    const orderByList: SC000_OrderByInfo[] = [{ item: 'eventEndDate', direction: 'ASC', }];
    const searchCondition: SC000_SearchCondition = {
      strDate: reqStrDate,
      endDate: reqEndDate,
      municipalitiesList: reqMunicipalitiesList,
      genreList: reqGenreList,
      keyword: reqKeyword,
      orderByList: orderByList,
    }

    // イベントリストを取得し、ステートを更新する関数（非同期処理を実装するため関数個別に関数化）
    try {
      const eventList = await getEventList(searchCondition)
      setEventListState(eventList)
      // 4. 検索条件をベースコンテキストに設定する
      baseDispatch(SC000_UPDATE_SEARCH_CONDITION(searchCondition))
    } catch (error) {
      console.error('Failed to fetch event list:', error)
    } finally {
      setIsLoading(false)  // ローディング終了
    }

    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(SCREEN_ID, 'initComponent')
    // ----------------------------------------------------------------------
  }

  useEffect(() => {
    const handleRouteChange = () => {
      resetScroll();
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      initComponent();
    } else {
      initComponent();
    }
  }, [location.search]);

  const areaList: SC000_Municipalities[] = baseState.prefecturesList.find(prefecture => prefecture.prefecturesId === '13_TOKYO')?.municipalitiesList || [];
  const genreList: SC000_Genre[] = baseState.genreList;

  // ----------------------------------------------------------------------
  // 終了ログ
  cm060_endLog(SCREEN_ID, 'SC130_C00_SearchResult')
  // ----------------------------------------------------------------------
  return (
    <div className='SC130_SearchResult'>
      <SC110_C01_Header />
      <div className="c02-negative-margin">
        <SC110_C02_SearchBar areaList={areaList} genreList={genreList} />
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-[200px]">
          <SC000_C02_LoadingSpinner
            size={8}
            color="bg-gray-400"
          />
        </div>
      ) : (
        <SC130_C01_SearchResInfo eventList={eventListState} />
      )}
      <SC110_C99_Footer />
    </div>
  );
};

export default SC130_C00_SearchResult;