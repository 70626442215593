import React, { useContext } from 'react';
import { SC000_Event } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { cm010_getImageUrl } from 'common/CM010_AppUtil'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { CONST_PAGE_URL } from 'common/CM000_Const'

interface SC140_C01_SearchResultDetailInfoProps {
  event: SC000_Event;
}

export const SC140_C01_SearchResultDetailInfo: React.FC<SC140_C01_SearchResultDetailInfoProps> = ({ event }) => {
  const { state: baseState } = useContext(SC000_S_Context);

  // YYYYMMDD 形式の文字列を YYYY/MM/DD 形式に変換する関数
  const formatDate = (dateString: string) => {
    if (!dateString || dateString.length !== 8) return '';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${year}/${month}/${day}`;
  };

  const eventDetails = [
    { label: '期間', value: `${formatDate(event.eventStrDate)} ～ ${formatDate(event.eventEndDate)}` },
    { label: '会場', value: event.eventPlace },
    { label: '住所', value: event.eventAddress },
    { label: '時間', value: event.eventTime },
    // { label: '料金', value: event.eventFee || '無料' },
    { label: 'お問い合わせ', value: event.contactInfo },
    { label: '参加対象', value: event.targetGroup },
    { label: '公式サイト', value: event.officialSiteUrl }
  ];

  // 区の取得
  const getMunicipalityName = (municipalityId: string) => {
    const prefecture = baseState.prefecturesList.find(
      pref => pref.prefecturesId === '13_TOKYO'
    );
    if (!prefecture) return '';

    const municipality = prefecture.municipalitiesList.find(
      m => m.municipalitiesId === municipalityId
    );
    return municipality ? municipality.municipalitiesName : '';
  };

  // ジャンルの取得
  const getGenreName = (genreId: string) => {
    const genre = baseState.genreList.find(g => g.genreId === genreId);
    return genre ? genre.genreName : '';
  };

  return (
    <div className="bg-white">
      <div className="max-w-[95%] mx-auto space-y-6 py-4">
        {/* イベント画像 */}
        <div className="aspect-video w-full overflow-hidden rounded-lg">
          <img
            src={cm010_getImageUrl(event.eventImageUrl, event.eventImageOriginUrl)}
            alt={event.eventName}
            className="w-full h-full object-contain"
          />
        </div>

        {/* イベント名 */}
        <h1 className="text-xl md:text-2xl font-bold">{event.eventName}</h1>

        {/* 区とジャンルタグ */}
        <div className="flex flex-wrap gap-1.5 items-center">
          {/* 区のタグ */}
          {event.municipalitiesId && (
            <span className="px-2 py-0.5 text-xs bg-blue-25 text-gray-600 rounded">
              {getMunicipalityName(event.municipalitiesId)}
            </span>
          )}

          {/* ジャンルタグ */}
          {event.eventGenreIdList && event.eventGenreIdList.map((genreId, index) => (
            <span
              key={index}
              className="px-2 py-0.5 text-xs bg-gray-25 text-gray-600 rounded"
            >
              {getGenreName(genreId)}
            </span>
          ))}
        </div>

        {/* イベント説明 */}
        <div className="rounded-lg">
          <p className="whitespace-pre-wrap text-sm md:text-base">{event.eventDiscription}</p>
        </div>

        {/* イベント詳細 - モバイルビュー */}
        <div className="md:hidden space-y-4">
          {eventDetails.map(({ label, value }) => value && (
            <div key={label} className="border rounded-lg overflow-hidden">
              <div className="bg-gray-50 px-4 py-2 text-sm font-medium">
                {label}
              </div>
              <div className="px-4 py-3 text-sm">
                {label === '公式サイト' && value ? (
                  <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline break-words"
                  >
                    {value}
                  </a>
                ) : (
                  <span className="break-words">{value}</span>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* イベント詳細テーブル - デスクトップビュー */}
        <div className="hidden md:block border rounded-lg overflow-hidden">
          <table className="w-full">
            <tbody>
              {eventDetails.map(({ label, value }) => (
                value && (
                  <tr key={label} className="border-b last:border-b-0">
                    <th className="bg-gray-50 p-4 text-left w-1/4 border-r">{label}</th>
                    <td className="p-4">
                      {label === '公式サイト' && value ? (
                        <a
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline"
                        >
                          {value}
                        </a>
                      ) : (
                        value
                      )}
                    </td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>

        {/* 公式サイトリンク */}
        {event.officialSiteUrl && (
          <div className="text-center pt-4">
            <a
              href={event.officialSiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 md:px-8 py-3 rounded-xl text-gray-700 bg-gray-100 text-sm md:text-base shadow-[-5px_-5px_10px_rgba(255,255,255,0.8),5px_5px_10px_rgba(0,0,0,0.1)] hover:shadow-[inset_-5px_-5px_10px_rgba(255,255,255,0.8),inset_5px_5px_10px_rgba(0,0,0,0.1)] transition-shadow focus:outline-none focus:ring-2 focus:ring-pink-300"
            >
              公式サイトへ
            </a>
          </div>
        )}

        {/* 注意事項 */}
        <div className="mt-8 px-4 py-3 bg-gray-50 rounded-lg">
          <p className="text-xs text-gray-600 leading-relaxed">
            【ご注意事項】<br />
            掲載情報は独自に収集しているため、イベントの開催期間・場所等が変更されている可能性があります。最新情報は必ず各自治体の公式サイトでご確認ください。
            <br /><br />
            情報の誤りを発見された場合は、お手数ですが<a href={CONST_PAGE_URL.SC100_C02_Contact} className="text-blue-600 hover:underline">お問い合わせ</a>よりご連絡ください。
          </p>
        </div>
      </div>
    </div>
  );
};