import { CONST_COMMON, CONST_ENV } from './CM000_Const'
import { cm010_isBlank } from 'common/CM010_AppUtil'
import { cm030_anyToString } from 'common/CM030_StringUtil'

const cm60_getLogLevelName = (logLevel: number) => {
    if (logLevel === CONST_COMMON.LOG.LOG_LEVEL.DEBUG) {
        return 'DEBUG'
    } else if (logLevel === CONST_COMMON.LOG.LOG_LEVEL.INFO) {
        return 'INFO'
    } else if (logLevel === CONST_COMMON.LOG.LOG_LEVEL.ALERT) {
        return 'ALERT'
    } else if (logLevel === CONST_COMMON.LOG.LOG_LEVEL.ERROR) {
        return 'ERROR'
    } else if (logLevel === CONST_COMMON.LOG.LOG_LEVEL.CRITICAL) {
        return 'CRITICAL'
    }
}

const cm060_printLog = (logLevel: number, message: any): void => {
    // 出力判定:指定されたログレベルが環境変数のログレベル以上の場合、ログを出力する
    if (logLevel >= CONST_ENV.LOG_LEVEL) {
        // 時刻の取得
        const nowISO: string = new Date().toISOString();
        // ログレベルの名称取得
        const logLevelName = cm60_getLogLevelName(logLevel)
        // ログ出力
        const strMessage = cm030_anyToString(message)
        console.log(nowISO + '[' + logLevelName + ']', strMessage)
    }
}


export const cm060_printPram = (logLevel: number, paramList: any[]) => {
    let count = 0
    for (const param of paramList) {
        count = count + 1
        const message_1 = '　　パラメータ[' + count + ']：'
        cm060_printLog(logLevel, message_1)
        const message_2 = '　　' + param
        cm060_printLog(logLevel, message_2)
    }
}

export const cm060_printLog_Methoｄ = (methodName: string) => {
    if (!cm010_isBlank(methodName)) {
        console.log('　　メソッド', '[', methodName, ']：')
    }
}

// DEBUG
export const cm060_debugLog = (message: any): void => {
    cm060_printLog(CONST_COMMON.LOG.LOG_LEVEL.DEBUG, message)
}

// INFO
export const cm060_infoLog = (message: any): void => {
    cm060_printLog(CONST_COMMON.LOG.LOG_LEVEL.INFO, message)
}

// ARART
export const cm060_alertLog = (message: any): void => {
    cm060_printLog(CONST_COMMON.LOG.LOG_LEVEL.ALERT, message)
}

// ERROR
export const cm060_errorLog = (message: any): void => {
    cm060_printLog(CONST_COMMON.LOG.LOG_LEVEL.ERROR, message)
}

// CRITICAL
export const cm060_criticalLog = (message: any): void => {
    cm060_printLog(CONST_COMMON.LOG.LOG_LEVEL.CRITICAL, message)
}

// START
export const cm060_strLog = (funcId: string, methodName: string, paramList: any[] = []) => {
    // ログレベルの指定
    const logLevel = CONST_COMMON.LOG.LOG_LEVEL.DEBUG
    // 判定
    // const processName = cm010_isBlank(methodName) ? '画面表示' : methodName
    // タグを作成
    const message = '[' + funcId + ':' + methodName + ':開始]▼▼▼------------------------------------------------------------'
    // ログ出力
    cm060_printLog(logLevel, message)
    // メソッド名を出力する
    // cm060_printLog_Methoｄ(methodName)
    // パラメータを出力する
    cm060_printPram(logLevel, paramList)
}

// END
export const cm060_endLog = (funcId: string, methodName: string, paramList: any[] = []) => {
    // ログレベルの指定
    const logLevel = CONST_COMMON.LOG.LOG_LEVEL.DEBUG
    // パラメータを出力する
    cm060_printPram(logLevel, paramList)
    // 判定
    // const processName = cm010_isBlank(methodName) ? '画面表示' : methodName
    // タグを作成
    const message = '[' + funcId + ':' + methodName + ':終了▲▲▲------------------------------------------------------------'
    // ログ出力
    cm060_printLog(logLevel, message)
}