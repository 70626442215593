export const cm010_isEmpty = (obj: any): boolean => {
    let result = false
    if (obj === null) {
        result = true
    } else if (obj === undefined) {
        result = true
        // } else if (obj == NaN) {
        //     errFlg = false
    }
    return result
}

export const cm010_isBlank = (obj: any): boolean => {
    let result = false
    if (obj === null) {
        result = true
    } else if (obj === undefined) {
        result = true
        // } else if (obj == NaN) {
        //     errFlg = false
    } else if (obj === '') {
        result = true
    }
    return result
}

export const cm010_getImageUrl = (eventImageUrl: string, eventImageOriginUrl: string) => {
    // noImageUrlの定義
    const NO_IMAGE_URL = "/icon/noimage.jpg";

    // 優先順位：eventImageUrl → eventImageOringUrl → NO_IMAGE
    if (eventImageUrl) return eventImageUrl;
    if (eventImageOriginUrl) return eventImageOriginUrl;
    return NO_IMAGE_URL;
}