import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
// import axios from 'axios';

import { CONST_REQ, CONST_SERVICE_URI, CONST_PAGE_URL } from 'common/CM000_Const'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { requestPost } from 'common/CM070_RequestUtil'
import { iDb_ClearAndBulkAdd_SC000_Prefectures, iDb_ClearAndBulkAdd_SC000_Genre, iDb_Put_SC000_IndexedDbHandler, iDb_GetALL_SC000_Prefectures, iDb_GetALL_SC000_Genre, iDb_GetALL_SC000_IndexedDbHandler } from 'common/CM080_IndexedDbUtil'
import { SC000_UPDATE_PREFECTURES_LIST, SC000_UPDATE_GENRE_LIST } from 'screens/SC000_BaseComponent/SC000_000_Action'
import { SC000_Prefectures, SC000_Genre, SC000_IndexedDbHandler } from 'screens/SC000_BaseComponent/SC000_000_Types'
import { SC000_S_Provider, SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC110_C00_Home } from 'screens/SC110_Home/SC110_C00_Home';
import { SC130_C00_SearchResult } from 'screens/SC130_SearchResult/SC130_C00_SearchResult'
import { SC140_C00_SearchResultDetail } from 'screens/SC140_SearchResultDetail/SC140_C00_SearchResultDetail'
import { SC999_C00_JICHITAI } from 'screens/SC999_JICHITAI/SC999_C00_JICHITAI';
import { SC999_C00_Test } from 'screens/SC999_Test/SC999_C00_Test';
import { SC100_C00_LandingPage } from 'screens/SC100_LandingPage/SC100_C00_LandingPage';
import { SC100_C01_Terms } from 'screens/SC100_LandingPage/SC100_C01_TermsPage';
import { SC100_C02_Contact } from 'screens/SC100_LandingPage/SC100_C02_ContactPage';
import { SC200_C00_ArticleList } from 'screens/SC200_ArticleList/SC200_C00_ArticleList';
import { SC210_C00_ArticleDetail } from 'screens/SC210_ArticleDetail/SC210_C00_ArticleDetail';
import { SC220_C00_ArticleCreate } from 'screens/SC220_ArticleCreate/SC220_C00_ArticleCreate';

const SCREEN_ID = 'SC000_C00'
// type UpdateMasterResult = [SC000_IndexedDbHandler[], string[]];

const MainComponent = () => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(SCREEN_ID, 'MainComponent')
    // ----------------------------------------------------------------------
    // 1. ベースコンテキストを取得する
    const { state: baseState, dispatch: baseDispatch } = useContext(SC000_S_Context)

    // 初期ロード時に実行する関数
    const initComponent = async () => {
        // ----------------------------------------------------------------------
        // 開始ログ
        cm060_strLog(SCREEN_ID, 'initComponent')
        // ----------------------------------------------------------------------
        // マスタハンドラ制御取得
        const iDbHandlerList = await iDb_GetALL_SC000_IndexedDbHandler()

        // リクエスト呼び出し(SR0290)
        const response_290 = await requestPost(CONST_SERVICE_URI.SR0290, {})
        const masterHandlerList = response_290.data.result.masterHandlerList as SC000_IndexedDbHandler[]

        // マスタ制御チェック
        const { updMasterList, updMasterIdList } = getUpdateMasterList(iDbHandlerList, masterHandlerList)

        // IndexedDBの都道府県マスタを最新化
        await updatePrefecturesIdb(updMasterIdList)

        // IndexedDBのジャンルマスタを最新化
        await updateGenreIdb(updMasterIdList)

        // IndexedDbから都道府県マスタの情報を取得する
        const prefecturesList = await iDb_GetALL_SC000_Prefectures()
        // コンテキストにDispatchする
        baseDispatch(SC000_UPDATE_PREFECTURES_LIST(prefecturesList))

        // IndexedDbからジャンルマスタの情報を取得する
        const genreList = await iDb_GetALL_SC000_Genre()
        // コンテキストにDispatchする
        baseDispatch(SC000_UPDATE_GENRE_LIST(genreList))

        // IndexedDBのマスタ制御を最新化
        await updateMasterHandlerIdb(updMasterList)
        // ----------------------------------------------------------------------
        // 終了ログ
        cm060_endLog(SCREEN_ID, 'initComponent')
        // ----------------------------------------------------------------------
    }
    // IndexedDBで更新が必要なマスタ情報の取得
    const getUpdateMasterList = (iDbHandlerList: SC000_IndexedDbHandler[], masterHandlerList: SC000_IndexedDbHandler[]) => {
        console.log(iDbHandlerList, masterHandlerList)
        // 0. 戻り値の初期化
        const updMasterList: SC000_IndexedDbHandler[] = [];
        const updMasterIdList: string[] = [];
        // 1. Mapの作成:
        // iDbHandlerListをMapに変換することで、tableIdをキーとして、iDbHandlerを値として迅速に検索できるようにする。
        const iDbHandlerMap = new Map(iDbHandlerList.map(item => [item.tableId, item]));
        // const masterHandlerMap = new Map(masterHandlerList.map(item => [item.tableId, item]));

        // // 2. 強制更新チェック
        // // テーブルID=M010_MasterHandler同士でチェックし、マスタの方がIndexedDBよりも新しい場合、全てのIndexedDbを最新化する
        // // M010_MasterHandlerの取得
        // const m010_iDb = iDbHandlerMap.get('M010_MasterHandler')
        // const m010_mst = masterHandlerMap.get('M010_MasterHandler')
        // // テーブル更新日時の比較
        // // ※ (!m010_iDb || !m010_mst) :m010_mstがundefinedになることはないのでm010_iDbがundefinedか否かのみを評価したいが、後続の判定で怒られるため「m010_mst」もundefined判定している
        // if ((!m010_iDb || !m010_mst) || new Date(m010_mst.tableUpdDatetime) > new Date(m010_iDb.tableUpdDatetime)) {
        //     // 全件洗い替えのため、マスタ側を返却
        //     return masterHandlerList;
        // }

        // 2. 更新チェック
        // masterHandlerListをループする: 
        masterHandlerList.forEach(masterHandler => {
            // iDbHandlerMapから、現在のmasterHandlerとtableIdが一致する要素を取得する
            const idbHandler = iDbHandlerMap.get(masterHandler.tableId);
            //   取得した要素が存在しない場合、またはmasterHandlerのtableUpdDatetimeが新しい場合は、updMasterListに追加する
            // updateDatetimeは文字列なので、new Date()でDateオブジェクトに変換して比較する
            if (!idbHandler || new Date(masterHandler.tableUpdDatetime) > new Date(idbHandler.tableUpdDatetime)) {
                // マスタ制御情報をリストに追加する
                updMasterList.push(masterHandler);
                // ID情報をリストに追加する
                updMasterIdList.push(masterHandler.tableId)
            }
            // 検証
            if (idbHandler !== undefined) {
                console.log(masterHandler.tableUpdDatetime, idbHandler.tableUpdDatetime)
                console.log(new Date(masterHandler.tableUpdDatetime), new Date(idbHandler.tableUpdDatetime))
            }
        });
        return { updMasterList, updMasterIdList };
    };

    // IndexedDBの都道府県マスタを最新化する関数
    const updatePrefecturesIdb = async (updMasterIdList: string[]) => {
        // IndexedDBの都道府県マスタを最新化
        // updMasterIdListにjudgeIdListの要素が含まれる場合、情報を更新する
        const judgeIdList = ['M010_MasterHandler', 'M110_PREFECTURES', 'M111_MUNICIPALITIES']
        const isIncluded = updMasterIdList.some(id => judgeIdList.includes(id));
        // 含まれている場合
        if (isIncluded) {
            // リクエスト呼び出し(SR0140)
            // const response_190 = await axios.post(CONST_SERVICE_URI_URI_URI.SR0190, {}, CONST_REQ.CONFIG)
            const response_190 = await requestPost(CONST_SERVICE_URI.SR0190, {})
            const prefecturesList = response_190.data.result.prefecturesList as SC000_Prefectures[]
            // Dispatch
            // baseDispatch(SC000_UPDATE_PREFECTURES_LIST(result_190))
            // IndexedDBに保存
            await iDb_ClearAndBulkAdd_SC000_Prefectures(prefecturesList)
            // [確認]IndexedDBから取得
            // const prefecturesList = await iDb_GetALL_SC000_Prefectures()
            // console.log('prefecturesList:', prefecturesList)
        }
    }

    // IndexedDBのジャンルマスタを最新化する関数
    const updateGenreIdb = async (updMasterIdList: string[]) => {
        // IndexedDBのジャンルマスタを最新化
        // updMasterIdListにjudgeIdListの要素が含まれる場合、情報を更新する
        const judgeIdList = ['M010_MasterHandler', 'M120_GENRE',]
        const isIncluded = updMasterIdList.some(id => judgeIdList.includes(id));
        // 含まれている場合
        if (isIncluded) {
            // リクエスト呼び出し(SR0140)
            const response_240 = await requestPost(CONST_SERVICE_URI.SR0240, {})
            const genreList = response_240.data.result.genreList as SC000_Genre[]
            // Dispatch
            // baseDispatch(SC000_UPDATE_GENRE_LIST(result_240))
            // IndexedDBに保存
            await iDb_ClearAndBulkAdd_SC000_Genre(genreList)
            // [確認]IndexedDBから取得
            // const genreList = await iDb_GetALL_SC000_Genre()
            // console.log('prefecturesList:', genreList)
        }
    }

    // IndexedDBのマスタ制御を最新化する関数
    const updateMasterHandlerIdb = async (updMasterList: SC000_IndexedDbHandler[]) => {
        // IndexedDBのマスタ制御を最新化
        updMasterList.map(async (updMaster) => {
            await iDb_Put_SC000_IndexedDbHandler(updMaster)
        })
    }

    // 初期表示処理（空の配列を渡すことで、初回レンダリング時のみ実行）
    useEffect(() => {
        // 初期化処理を実行
        initComponent();
    }, []);
    // DEBUGログ
    cm060_debugLog('baseState:' + JSON.stringify(baseState))
    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(SCREEN_ID, 'MainComponent')
    // ----------------------------------------------------------------------
    return (
        <>
            {/* ルーティングを設定 */}
            <BrowserRouter>
                <Routes>
                    <Route path={CONST_PAGE_URL.SC100_C00_LandingPage} Component={SC100_C00_LandingPage} />
                    <Route path={CONST_PAGE_URL.SC100_C01_Terms} Component={SC100_C01_Terms} />
                    <Route path={CONST_PAGE_URL.SC100_C02_Contact} Component={SC100_C02_Contact} />
                    <Route path='/' Component={SC110_C00_Home} />
                    <Route path={CONST_PAGE_URL.SC110_C00_Home} Component={SC110_C00_Home} />
                    <Route path={CONST_PAGE_URL.SC130_C00_SearchResult} Component={SC130_C00_SearchResult} />
                    <Route path={CONST_PAGE_URL.SC140_C00_SearchResultDetail + '/:urlEventId'} Component={SC140_C00_SearchResultDetail} />
                    <Route path={CONST_PAGE_URL.SC200_C00_ArticleList} Component={SC200_C00_ArticleList} />
                    <Route path={CONST_PAGE_URL.SC210_C00_ArticleDetail + '/:articleId'} Component={SC210_C00_ArticleDetail} />
                    <Route path={CONST_PAGE_URL.SC220_C00_ArticleCreate} Component={SC220_C00_ArticleCreate} />
                    {/* <Route path='/SC120_C00_JICHITAI' Component={SC999_C00_JICHITAI} /> */}
                    <Route path='/SC999_C00_Test' Component={SC999_C00_Test} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export const SC000_C00_BaseComponent = () => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(SCREEN_ID, 'SC000_C00_BaseComponent')
    // ----------------------------------------------------------------------
    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(SCREEN_ID, 'SC000_C00_BaseComponent')
    // ----------------------------------------------------------------------
    return (
        <>
            {/* コンテキストのプロバイダーを設定 */}
            <SC000_S_Provider>
                <MainComponent />
            </SC000_S_Provider>
        </>
    );
};