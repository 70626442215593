import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const'

interface FeaturedContent {
  id: number;
  title: string;
  image: string;
  link: string;
}

const featuredContents: FeaturedContent[] = [
  {
    id: 1,
    title: '特集1',
    image: '/pic/featured/about.png',
    link: CONST_PAGE_URL.SC100_C00_LandingPage
  },
  { id: 2, title: '特集2', image: '/pic/featured/event_child.png', link: '' },
  // { id: 3, title: '特集3', image: '/pic/koto-ku.jpg', link: '' },
  // { id: 4, title: '特集4', image: '/pic/shibuya-ku.jpg', link: '' },
  // { id: 5, title: '特集5', image: '/pic/shinjuku-ku.jpg', link: '' },
];

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const NextArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <div
      className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <div className="w-0 h-0 border-t-[12px] md:border-t-[18px] border-t-transparent border-b-[12px] md:border-b-[18px] border-b-transparent border-l-[16px] md:border-l-[24px] border-l-white opacity-80 hover:opacity-100 transition-opacity" />
    </div>
  );
}

const PrevArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <div
      className="absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <div className="w-0 h-0 border-t-[12px] md:border-t-[18px] border-t-transparent border-b-[12px] md:border-b-[18px] border-b-transparent border-r-[16px] md:border-r-[24px] border-r-white opacity-80 hover:opacity-100 transition-opacity" />
    </div>
  );
}

export const SC110_C03_EventBanner = () => {
  const navigate = useNavigate();

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const handleSlideClick = (link: string) => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div className="w-full bg-white py-4 md:py-8">
      <div className="relative max-w-screen-2xl mx-auto">
        <Slider {...settings}>
          {featuredContents.map((featuredContent) => (
            <div
              key={featuredContent.id}
              onClick={() => handleSlideClick(featuredContent.link)}
              className="focus:outline-none"
            >
              <div className={`relative h-48 sm:h-56 md:h-80 lg:h-[24rem] xl:h-[28rem] 2xl:h-[32rem] overflow-hidden ${featuredContent.link ? 'cursor-pointer' : ''}`}>
                <img
                  src={featuredContent.image}
                  alt={`${featuredContent.title} `}
                  className="w-full h-full object-cover object-center"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};