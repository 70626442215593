import axios from 'axios';
import React, { useState, useContext, memo } from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Grid, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { dateToString } from 'common/CM050_DateUtil'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_UPDATE_SEARCH_CONDITION } from 'screens/SC000_BaseComponent/SC000_000_Action'
import { SC000_SearchCondition } from 'screens/SC000_BaseComponent/SC000_000_Types'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC999_C01_Search } from 'screens/SC999_Test/SC999_C01_Search'
import { SC999_C02_Article } from 'screens/SC999_Test/SC999_C02_Article'

const SCREEN_ID = 'SC999_C00'

const municipalities = ['Tokyo', 'Osaka', 'Kyoto']; // 仮のデータ
const genres = ['Music', 'Sports', 'Technology']; // 仮のデータ

// イベントの型定義
type Event = {
    eventId: string;
    eventName: string;
    eventStrDate: string;
    eventEndDate: string;
};

const EventList = (props: { eventList: Event[] }) => {
    const eventList = props.eventList
    return (
        <div>
            <h1>イベントリスト</h1>
            <ul>
                {eventList.map((event) => (
                    <li key={event.eventId}>
                        <h2>{event.eventName}</h2>
                        <p>開始日: {event.eventStrDate}</p>
                        <p>終了日: {event.eventEndDate}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const SC999_C00_Test = () => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(SCREEN_ID, 'SC999_C00_Test')
    // ----------------------------------------------------------------------

    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(SCREEN_ID, 'SC999_C00_Test')
    // ----------------------------------------------------------------------
    return (
        <>
            {/* <SC999_C01_Search /> */}
            <SC999_C02_Article />
        </>
    );
};
