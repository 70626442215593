import React from 'react';
import { SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';

interface SC120_C03_GenreSearchProps {
  genreList: SC000_Genre[];
  selectedGenres: string[];
  handleGenreToggle: (genreId: string) => void;
}

export const SC120_C03_GenreSearch: React.FC<SC120_C03_GenreSearchProps> = ({
    genreList, selectedGenres, handleGenreToggle
  }) => {
    return (
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">ジャンル検索</h3>
        <div className="grid grid-cols-5 gap-2">
          {genreList.map((genre) => (
            <div
              key={genre.genreId}
              onClick={() => handleGenreToggle(genre.genreId)}
              className={`
                flex flex-col items-center cursor-pointer rounded-lg transition-colors
                ${selectedGenres.includes(genre.genreId) 
                  ? 'text-pink-500 bg-pink-50' 
                  : 'text-gray-600 hover:bg-gray-50'
                }
              `}
            >
              <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center mb-1">
                <img
                  src={`/genre/${genre.genreId}.jpg`}
                  alt={`${genre.genreName}の画像`}
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
              <span className="text-xs text-center leading-tight">{genre.genreName}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };