import React, { useState, useContext, useEffect } from 'react';
import { X } from 'lucide-react';
import { Search } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_UPDATE_SEARCH_CONDITION } from 'screens/SC000_BaseComponent/SC000_000_Action'
import { SC000_SearchCondition } from 'screens/SC000_BaseComponent/SC000_000_Types'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { SC120_C01_DateSearch } from 'screens/SC120_Search/SC120_C01_DateSearch';
import { SC120_C02_AreaSearch } from 'screens/SC120_Search/SC120_C02_AreaSearch';
import { SC120_C03_GenreSearch } from 'screens/SC120_Search/SC120_C03_GenreSearch';
import { SC120_C04_KeywordSearch } from 'screens/SC120_Search/SC120_C04_KeywordSearch';
import { getEventList } from 'screens/SC130_SearchResult/SC130_C00_SearchResult'

const SCREEN_ID = 'SC120_C00'

interface SC120_C00_SearchFilterProps {
  areaList: SC000_Municipalities[];
  genreList: SC000_Genre[];
  onClose: () => void;
}

// 他画面でも利用するので、検索条件コンテキスト更新関数をカスタムフック化する
export const useSearchCondition = () => {
  // 1. ベースコンテキストを取得する
  const { state: baseState, dispatch: baseDispatch } = useContext(SC000_S_Context)
  const baseSearchCondition = baseState.searchCondition
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  // const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  // const [keyword, setKeyword] = useState('');

  // ナビゲーターを取得
  const navigate = useNavigate();

  // 検索条件（開始日）を更新する関数
  const handleStrDateForm = (strDate: string) => {
    // ログ出力
    console.log('START', baseSearchCondition)
    // 更新後の検索条件を作成
    const newSearchCondition: SC000_SearchCondition = {
      ...baseSearchCondition,
      strDate: strDate,
    }
    // コンテキストの更新（検索条件）
    baseDispatch(SC000_UPDATE_SEARCH_CONDITION(newSearchCondition))
    // ログ出力
    console.log('END', baseSearchCondition)
  }

  // 検索条件（終了日）を更新する関数
  const handleEndDateForm = (endDate: string) => {
    // ログ出力
    console.log('START', baseSearchCondition)
    // 更新後の検索条件を作成
    const newSearchCondition: SC000_SearchCondition = {
      ...baseSearchCondition,
      endDate: endDate,
    }
    // コンテキストの更新（検索条件）
    baseDispatch(SC000_UPDATE_SEARCH_CONDITION(newSearchCondition))
    // ログ出力
    console.log('END', baseSearchCondition)
  }

  // 検索条件（市区町村）を更新する関数
  const handleAreaToggle = (municipalitiesId: string) => {
    // setSelectedAreas(prev =>
    //   prev.includes(areaId) ? prev.filter(id => id !== areaId) : [...prev, areaId]
    // );
    // ログ出力
    console.log('START', baseSearchCondition)
    // 旧条件の取得（スプレッド構文を用いたコピー）
    const prevList = [...baseSearchCondition.municipalitiesList]
    // 三項演算子
    // リストに指定のIDが含まれる場合、指定のIDを除いた配列を返す。左記以外の場合、リストに指定のIDを追加する。
    // 注意）List.filterは元の配列を変更しない
    const newList = prevList.includes(municipalitiesId) ? prevList.filter(id => id !== municipalitiesId) : [...prevList, municipalitiesId]
    // 更新後の検索条件を作成
    const newSearchCondition: SC000_SearchCondition = {
      ...baseSearchCondition,
      municipalitiesList: newList,
    }
    // コンテキストの更新（検索条件）
    baseDispatch(SC000_UPDATE_SEARCH_CONDITION(newSearchCondition))
    // ログ出力
    console.log('END', baseSearchCondition)
  };

  // 検索条件（ジャンル）を更新する関数
  const handleGenreToggle = (genreId: string) => {
    // setSelectedGenres(prev =>
    //   prev.includes(genreId) ? prev.filter(id => id !== genreId) : [...prev, genreId]
    // );
    // ログ出力
    console.log('START', baseSearchCondition)
    // 旧条件の取得（スプレッド構文を用いたコピー）
    const prevList = [...baseSearchCondition.genreList]
    // 三項演算子
    // リストに指定のIDが含まれる場合、指定のIDを除いた配列を返す。左記以外の場合、リストに指定のIDを追加する。
    // 注意）List.filterは元の配列を変更しない
    const newList = prevList.includes(genreId) ? prevList.filter(id => id !== genreId) : [...prevList, genreId]
    // 更新後の検索条件を作成
    const newSearchCondition: SC000_SearchCondition = {
      ...baseSearchCondition,
      genreList: newList,
    }
    // コンテキストの更新（検索条件）
    baseDispatch(SC000_UPDATE_SEARCH_CONDITION(newSearchCondition))
    // ログ出力
    console.log('END', baseSearchCondition)
  };

  // 検索条件（キーワード）を更新する関数
  const handleKeywordForm = (keyword: string) => {
    // ログ出力
    console.log('START', baseSearchCondition)
    // 更新後の検索条件を作成
    const newSearchCondition: SC000_SearchCondition = {
      ...baseSearchCondition,
      keyword: keyword,
    }
    // コンテキストの更新（検索条件）
    baseDispatch(SC000_UPDATE_SEARCH_CONDITION(newSearchCondition))
    // ログ出力
    console.log('END', baseSearchCondition)
  }

  // 検索実行時のイベントハンドラ関数
  const executeSearch = () => {
    // コンテキストの検索条件でURLパラメータを作成する
    const newSearchParams = new URLSearchParams();
    // 開始日を設定する
    newSearchParams.append('strDate', baseSearchCondition.strDate);
    // 終了日を設定する
    newSearchParams.append('endDate', baseSearchCondition.endDate);
    // 市区町村IDリストの各要素を個別のパラメータとして設定する
    baseSearchCondition.municipalitiesList.forEach((municipalitiesId, index) => {
      // newSearchParams.append(`municipalitiesList_${index}`, municipalitiesId);
      newSearchParams.append('municipalitiesList', municipalitiesId); //indexなしでも、遷移先でgetAllすればいける
    });
    // ジャンルIDリストの各要素を個別のパラメータとして設定する
    baseSearchCondition.genreList.forEach((genreId, index) => {
      // newSearchParams.append(`genreList`, genreId);
      newSearchParams.append('genreList', genreId); //indexなしでも、遷移先でgetAllすればいける
    });
    // キーワードを設定する
    newSearchParams.append('keyword', baseSearchCondition.keyword);
    // URLパラメータを作成し、画面遷移する。
    navigate(CONST_PAGE_URL.SC130_C00_SearchResult + `?${newSearchParams.toString()}`);
    //TODO:もっといい方法ありそう
    // 画面をリロードする 
    window.location.reload();
    // 画面を閉じる
    // onClose();
  };

  return {
    baseSearchCondition,
    handleStrDateForm,
    handleEndDateForm,
    handleAreaToggle,
    handleGenreToggle,
    handleKeywordForm,
    executeSearch,
  }
}

export const SC120_C00_SearchFilter: React.FC<SC120_C00_SearchFilterProps> = ({ areaList, genreList, onClose }) => {
  // ナビゲーターを取得
  const navigate = useNavigate();

  // 検索条件カスタムフックを取得
  const {
    baseSearchCondition: baseSearchCondition,
    handleStrDateForm: handleStrDateForm,
    handleEndDateForm: handleEndDateForm,
    handleAreaToggle: handleAreaToggle,
    handleGenreToggle: handleGenreToggle,
    handleKeywordForm: handleKeywordForm,
    executeSearch: executeSearch,
  } = useSearchCondition()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
      // bodyのスクロールを禁止
      document.body.style.overflow = 'hidden';
    }

    // クリーンアップ関数
    return () => {
      if (typeof window !== 'undefined') {
        // モーダルが閉じられた時にbodyのスクロールを復元
        document.body.style.overflow = 'unset';
      }
    };
  }, []); // 空の依存配列で、マウント時とアンマウント時のみ実行

  // 検索ボタンクリック時のイベントハンドラ関数
  const onClickSearch = () => {
    // 検索実行関数を実行
    executeSearch()
    // // コンテキストの検索条件でURLパラメータを作成する
    // const newSearchParams = new URLSearchParams();
    // // 開始日を設定する
    // newSearchParams.append('strDate', baseSearchCondition.strDate);
    // // 終了日を設定する
    // newSearchParams.append('endDate', baseSearchCondition.endDate);
    // // 市区町村IDリストの各要素を個別のパラメータとして設定する
    // baseSearchCondition.municipalitiesList.forEach((municipalitiesId, index) => {
    //   // newSearchParams.append(`municipalitiesList_${index}`, municipalitiesId);
    //   newSearchParams.append('municipalitiesList', municipalitiesId); //indexなしでも、遷移先でgetAllすればいける
    // });
    // // ジャンルIDリストの各要素を個別のパラメータとして設定する
    // baseSearchCondition.genreList.forEach((genreId, index) => {
    //   // newSearchParams.append(`genreList`, genreId);
    //   newSearchParams.append('genreList', genreId); //indexなしでも、遷移先でgetAllすればいける
    // });
    // // キーワードを設定する
    // newSearchParams.append('keyword', baseSearchCondition.keyword);
    // // URLパラメータを作成し、画面遷移する。
    // navigate(`/SC130_C00_SearchResult?${newSearchParams.toString()}`);
    // //TODO:もっといい方法ありそう
    // // 画面をリロードする 
    // window.location.reload();
    // // 画面を閉じる
    // onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md max-h-[85vh] md:max-h-[90vh] overflow-y-auto mt-[-20px] md:mt-0"
      >
        <div className="sticky top-0 bg-white z-10 p-3 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-semibold">イベントを探す</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            aria-label="閉じる"
          >
            <X size={24} />
          </button>
        </div>

        <div className="p-3 space-y-4">
          <SC120_C01_DateSearch
            strDate={baseSearchCondition.strDate}
            endDate={baseSearchCondition.endDate}
            handleStrDateForm={handleStrDateForm}
            handleEndDateForm={handleEndDateForm}
          />

          <SC120_C02_AreaSearch
            areaList={areaList}
            selectedAreas={baseSearchCondition.municipalitiesList}
            handleAreaToggle={handleAreaToggle}
          />

          <SC120_C03_GenreSearch
            genreList={genreList}
            selectedGenres={baseSearchCondition.genreList}
            handleGenreToggle={handleGenreToggle}
          />

          <SC120_C04_KeywordSearch
            keyword={baseSearchCondition.keyword}
            handleKeywordForm={handleKeywordForm}
          />
        </div>

        <div className="sticky bottom-0 bg-white z-10 p-3 border-t border-gray-200">
          <button
            onClick={onClickSearch}
            className="w-full bg-gray-100 text-gray-800 py-2 rounded-lg font-semibold transition-all duration-300 ease-in-out
              shadow-[5px_5px_10px_rgba(0,0,0,0.1),-5px_-5px_10px_rgba(255,255,255,0.8)]
              hover:shadow-[2px_2px_5px_rgba(0,0,0,0.1),-2px_-2px_5px_rgba(255,255,255,0.8)]
              active:shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),inset_-2px_-2px_5px_rgba(255,255,255,0.8)]"
          >
            検索
          </button>
        </div>
      </div>
    </div>
  );
};