import React, { useState }  from 'react';
import { Search } from 'lucide-react';
import { useSearchCondition } from 'screens/SC120_Search/SC120_C00_SearchFilter';
import { SC110_C10_SearchFilterDisplay } from 'screens/SC110_Home/SC110_C10_SearchFilterDisplay';
import { SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';

interface SearchBarProps {
  areaList: SC000_Municipalities[];
  genreList: SC000_Genre[];
}

export const SC110_C02_SearchBar: React.FC<SearchBarProps> = ({ areaList, genreList }) => {
  const { baseSearchCondition, handleKeywordForm } = useSearchCondition();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  return (
    <div className="relative">
      <div className="p-4 bg-white flex justify-center">
        <div className="relative w-full max-w-2xl">
          <div
            onClick={togglePopup}
            className="w-full py-4 pl-6 pr-12 bg-white rounded-full text-gray-700 placeholder-gray-400
                     shadow-[6px_6px_12px_rgba(0,0,0,0.05),-6px_-6px_12px_rgba(255,255,255,0.9),inset_1px_1px_2px_rgba(0,0,0,0.05),inset_-1px_-1px_2px_rgba(255,255,255,0.9)]
                     focus:outline-none text-sm md:text-base cursor-pointer select-none"
            style={{ pointerEvents: 'auto' }}
          >
            イベントを探す
          </div>
          <button
            onClick={togglePopup}
            className="absolute right-6 md:right-2 top-1/2 transform -translate-y-1/2 bg-[#f47089] text-white p-2 rounded-full hover:bg-[#d8647b] focus:outline-none"
          >
            <Search className="w-5 h-5 text-white" />
          </button>
        </div>
      </div>
      <SC110_C10_SearchFilterDisplay
        areaList={areaList}
        genreList={genreList}
        isPopupOpen={isPopupOpen}
        togglePopup={togglePopup}
      />
    </div>
  );
};