import React from 'react';
import fs from 'fs';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CONST_SERVICE_URI, CONST_SERVICE_RESULT_STATUS } from 'common/CM000_Const'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_ArticleInfo, SC000_SearchCondition, SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { cm060_strLog, cm060_endLog } from 'common/CM060_LogUtil';
import { requestPost, requestPostMulti } from 'common/CM070_RequestUtil'
import { getDownloadUrlFromStorageFile } from 'common/CM100_FirebaseUtil'
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header';
import { SC000_C02_LoadingSpinner } from 'screens/SC000_BaseComponent/SC000_C02_LoadingSpinner';
import { SC110_C99_Footer } from 'screens/SC110_Home/SC110_C99_Footer';

const SCREEN_ID = 'SC140_C00';

// // イベント詳細取得
// const getArticleDetailInfo = async (articleId: string): Promise<SC000_ArticleInfo> => {
//   // ----------------------------------------------------------------------
//   cm060_strLog(SCREEN_ID, 'getArticleDetailInfo')
//   // ----------------------------------------------------------------------
//   // リクエスト呼び出し(SR0130)
//   const searchConditionOnlyId = {
//     articleId: articleId,
//   }
//   const response_330 = await requestPost(CONST_SERVICE_URI.SR0330, searchConditionOnlyId)
//   const articleDetailInfo = response_330.data.result.articleDetailInfo || {}
//   console.log('articleDetailInfo:', articleDetailInfo)
//   // ----------------------------------------------------------------------
//   // 終了ログ
//   cm060_endLog(SCREEN_ID, 'getArticleDetailInfo')
//   // ----------------------------------------------------------------------
//   // 戻り値を返却
//   return articleDetailInfo
// }

export const SC220_C00_ArticleCreate = () => {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog(SCREEN_ID, 'SC220_C00_ArticleCreate');
  // ----------------------------------------------------------------------
  // State
  const [articleTitle, setArticleTitle] = useState('');
  const [articleAbout, setArticleAbout] = useState('');
  const [htmlFile, setHtmlFile] = useState<File | null>(null);
  const [articleHtmlFilepath, setArticleHtmlFilepath] = useState('');
  // const [articleHtmlDLUrl, setArticleHtmlDLUrl] = useState('');
  const [mainImage, setMainImage] = useState<File | null>(null);
  const [articleMainImageFilepath, setArticleMainImageFilepath] = useState('');
  // const [articleMainImageDLUrl, setArticleMainImageDLUrl] = useState('');
  const [prefecturesIdList, setPrefecturesIdList] = useState<number[]>([]);
  const [municipalitiesIdList, setMunicipalitiesIdList] = useState<number[]>([]);
  const [articleGenreIdList, setArticleGenreIdList] = useState<number[]>([]);
  const [articleKbn, setArticleKbn] = useState('0');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setHtmlFile(e.target.files[0]);
      setArticleHtmlFilepath(e.target.files[0].name);
    }
  };

  const handleMainImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setMainImage(e.target.files[0]);
      setArticleMainImageFilepath(e.target.files[0].name);
    }
  }

  // 記事登録処理
  const handleSubmit = async () => {
    if (!htmlFile) {
      // null回避
      return
    }
    if (!mainImage) {
      // null回避
      return
    }
    // 1.) ファイルとDBの登録
    // ファイルをbase64に変換
    const reader = new FileReader();
    const htmlFileBase64 = await new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(htmlFile);
    });
    const mainImagease64 = await new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(mainImage);
    });
    console.log('htmlFileBase64', htmlFileBase64)
    // リクエストデータ作成
    const requestDataSR0310 = {
      articleTitle,
      articleAbout,
      htmlFile: htmlFileBase64,
      articleHtmlFilepath,
      articleHtmlDLUrl: '',
      mainImage: mainImagease64,
      articleMainImageFilepath,
      articleMainImageDLUrl: '',
      prefecturesIdList,
      municipalitiesIdList,
      articleGenreIdList,
      articleKbn,
    };
    // リクエストを実行
    const responseSR0310 = await requestPost(CONST_SERVICE_URI.SR0310, requestDataSR0310);

    // 2.) ダウンロード用URLの登録 
    // レスポンス結果を取得
    const articleId = responseSR0310.data.result.articleId
    const UploadArticleHtmlFilepath = responseSR0310.data.result.articleHtmlFilepath
    const UploadMainImageFilepath = responseSR0310.data.result.articleMainImageFilepath
    // ダウンロード用URLを取得
    const articleHtmlDLUrl = await getDownloadUrlFromStorageFile(UploadArticleHtmlFilepath)
    const articleMainImageDLUrl = await getDownloadUrlFromStorageFile(UploadMainImageFilepath)
    // リクエストデータ作成
    const requestDataSR0321 = {
      articleId: articleId,
      articleHtmlDLUrl: articleHtmlDLUrl,
      articleMainImageDLUrl: articleMainImageDLUrl,
    };
    // リクエストを実行
    const responseSR0321 = await requestPost(CONST_SERVICE_URI.SR0321, requestDataSR0321);

    // try {
    //   const response = await requestPost(CONST_SERVICE_URI.SR0310, requestData);
    //   // const response = await requestPostMulti(CONST_SERVICE_URI.SR0310, requestData);
    //   console.log('Article created successfully:', response.data);
    // } catch (error) {
    //   console.error('Error creating article:', error);
    // }
  };
  // ----------------------------------------------------------------------
  cm060_endLog(SCREEN_ID, 'SC220_C00_ArticleCreate')
  // ----------------------------------------------------------------------
  return (
    <div className='SC220_ArticleCreate'>
      <h1>記事作成</h1>
      <div>
        <label>記事タイトル:</label>
        <input
          type='text'
          value={articleTitle}
          onChange={(e) => setArticleTitle(e.target.value)}
        />
      </div>
      <div>
        <label>記事概要:</label>
        <textarea
          value={articleAbout}
          onChange={(e) => setArticleAbout(e.target.value)}
        />
      </div>
      <div>
        <label>記事HTMLファイル:</label>
        <input
          type='file'
          accept='.html'
          onChange={(e) => { handleFileChange(e) }}
        />
      </div>
      <div>
        <label>記事メイン画像:</label>
        <input
          type='file'
          accept='image/*'
          onChange={(e) => { handleMainImageFileChange(e) }}
        />
      </div>
      <div>
        <button onClick={handleSubmit}>登録</button>
      </div>
    </div>
  );
};

export default SC220_C00_ArticleCreate;