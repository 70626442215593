import { cm010_isBlank } from 'common/CM010_AppUtil'

export const cm030_anyToString = (obj: any): string => {
    if (typeof (obj) === 'object') {
        return cm030_objToString(obj)
    }
    else {
        return String(obj)
    }
}

export const cm030_objToString = (obj: object): string => {
    return JSON.stringify(obj)
}

// 指定の区切り文字で、文字列をリストに分割する処理
export const cm030_stringToList = (str: string, divider: string): string[] => {
    // 空文字チェック
    if (cm010_isBlank(str)) {
        // 空文字の場合、空のリストを返却する
        return []
    } else {
        // 空文字でない場合、文字列を結合してリストを返却する
        const strList: string[] = str.split(divider);
        return strList
    }
}