import { createContext, useReducer } from 'react';
import { CONST_SC000 } from 'common/CM000_Const';
import type { SC000_Provider, SC000_Context, SC000_LoginUserInfo } from 'screens/SC000_BaseComponent/SC000_000_Types'
import { reducer, } from 'screens/SC000_BaseComponent//SC000_000_Reducer'

// 1.コンテキスト（S999_S_Context）
export const SC000_S_Context = createContext<SC000_Provider>({} as SC000_Provider);

// 2.コンテキストの初期値(DEFAULTState)
// コンテキストに値を追加する場合、ここに初期値も追加する必要がある。
// ==========================================================================================
// 各要素の初期値を定義
const DEFAULT_ScreenControllerInfo = {
    screenId: CONST_SC000.SCREENINFO.SC010.SCREENID,
    headerKbn: CONST_SC000.SCREENINFO.SC010.HEADERKBN,
    footerKbn: CONST_SC000.SCREENINFO.SC010.FOOTERKBN,
    // layoutPattern: 1,ß
    // infoObj: {}
}
const DEFAULT_LoginUserInfo = {} as SC000_LoginUserInfo
const DEFAULT_ErrorInfo = {
    errorKbn: 0
}

const DEFAULT_SearchCondition = {
    strDate: '',
    endDate: '',
    municipalitiesList: [],
    genreList: [],
    keyword: ''
}

// SC110
const DEFAULT_BaseContext_SC110 = {
    screenInfo: {
        screenTitle: 'デフォルトタイトル',
        screenText: 'デフォルトテキスト',
    }
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// コンテキストに↑で定義した初期値を設定し、デフォルトステートを作成
const DEFAULT_State: SC000_Context = {
    screenControllerInfo: DEFAULT_ScreenControllerInfo,
    loginUserInfo: DEFAULT_LoginUserInfo,
    errorInfo: DEFAULT_ErrorInfo,
    searchCondition: DEFAULT_SearchCondition,
    prefecturesList: [],
    genreList: [],
    baseContext_SC110: DEFAULT_BaseContext_SC110,
}
// ==========================================================================================

// 3.プロバイダ(SC010_S_Provider)
//　詳しい説明はげぇじ本P183を参照
export const SC000_S_Provider = (props: any) => {
    // JSXでchildrenを使うため、propsからchildrenを取得する
    const { children } = props;

    // useReducerを使ってstateとdispatchを取得する
    const [state, dispatch] = useReducer(reducer, DEFAULT_State)

    // valueの中に{state, dispatch}を設定し、childrenコンポーネントで使えるようにする
    return (
        <SC000_S_Context.Provider value={{ state, dispatch }}>
            {children}
        </SC000_S_Context.Provider>
    );
};
