import React from 'react';
import { SC000_Municipalities } from 'screens/SC000_BaseComponent/SC000_000_Types';

interface SC120_C02_AreaSearchProps {
  areaList: SC000_Municipalities[];
  selectedAreas: string[];
  handleAreaToggle: (areaId: string) => void;
}

export const SC120_C02_AreaSearch: React.FC<SC120_C02_AreaSearchProps> = ({
    areaList, selectedAreas, handleAreaToggle
  }) => {
    return (
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">エリア検索</h3>
        <div className="flex flex-wrap gap-2">
          {areaList.map((area) => (
            <button
              key={area.municipalitiesId}
              onClick={() => handleAreaToggle(area.municipalitiesId)}
              className={`
                px-3 py-1 rounded-md text-sm transition-colors
                ${selectedAreas.includes(area.municipalitiesId)
                  ? 'bg-pink-50 text-pink-500'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }
              `}
            >
              {area.municipalitiesName}
            </button>
          ))}
        </div>
      </div>
    );
  };