import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Search } from 'lucide-react';
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header'
import { SC110_C02_SearchBar } from 'screens/SC110_Home/SC110_C02_SearchBar'
import { SC110_C03_EventBanner } from 'screens/SC110_Home/SC110_C03_EventBanner'
import { SC110_C04_EventSearch } from 'screens/SC110_Home/SC110_C04_EventSearch'
import { SC110_C05_AreaSearch } from 'screens/SC110_Home/SC110_C05_AreaSearch'
import { SC110_C06_GenreSearch } from 'screens/SC110_Home/SC110_C06_GenreSearch'
import { SC110_C08_CurrentEvents } from 'screens/SC110_Home/SC110_C08_CurrentEvents'
import { SC110_C09_MonthlyEvents } from 'screens/SC110_Home/SC110_C09_MonthlyEvents'
import { SC000_C02_LoadingSpinner } from 'screens/SC000_BaseComponent/SC000_C02_LoadingSpinner'
import { SC110_C99_Footer } from 'screens/SC110_Home/SC110_C99_Footer'

const SCREEN_ID = 'SC110_C00'

export const SC110_C00_Home = () => {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog(SCREEN_ID, 'SC110_C00_Home')
  // ----------------------------------------------------------------------

  // 各コンポーネントのローディング状態管理
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isAreaLoading, setIsAreaLoading] = useState(true);
  const [isGenreLoading, setIsGenreLoading] = useState(true);

  // 1. ベースコンテキストを取得する
  const { state: baseState } = useContext(SC000_S_Context)

  // コンテキストの初期データチェック
  useEffect(() => {
    if (!baseState.prefecturesList || !baseState.genreList) {
      setIsInitialLoading(true);
    } else {
      setIsInitialLoading(false);
    }
  }, [baseState]);

  // 2. 東京都の市区町村情報の取得
  // ベースコンテキストから都道府県マスタの情報を取得する
  const prefecturesList = baseState.prefecturesList
  // 都道府県マスタの情報から、東京都の情報のみを抜き取る
  let tokyoMunicipalitiesList = [] as SC000_Municipalities[]
  prefecturesList?.map((prefectures) => {
    // 東京都の都道府県IDを検索する
    if (prefectures.prefecturesId === '13_TOKYO') {
      tokyoMunicipalitiesList = prefectures.municipalitiesList
    }
  })

  // 3. ジャンル情報の取得
  // ベースコンテキストからジャンルマスタの情報を取得する
  const genreList = baseState.genreList

  // ----------------------------------------------------------------------
  // 終了ログ
  cm060_endLog(SCREEN_ID, 'SC110_C00_Home')
  // ----------------------------------------------------------------------

  // 初期ローディング中はスピナーを表示
  if (isInitialLoading) {
    return <SC000_C02_LoadingSpinner fullScreen={true} />;
  }

  return (
    <div className="SC110_C00_Home bg-white">
      <div className="fixed top-0 left-0 right-0 z-50 bg-white">
        <div className="max-w-screen-2xl mx-auto px-4">
          <SC110_C01_Header />
          <div className="c02-negative-margin">
            <SC110_C02_SearchBar
              areaList={tokyoMunicipalitiesList}
              genreList={genreList}
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-[1px] bg-gray-200"></div>
      </div>

      <div className="h-[115px] md:h-[152px] bg-white"></div>

      <SC110_C03_EventBanner />
      <div className="c04-margin">
        <SC110_C05_AreaSearch
          areaList={tokyoMunicipalitiesList}
          isLoading={isAreaLoading}
          setIsLoading={setIsAreaLoading}
        />
      </div>

      <div className="c06-negative-margin">
        <SC110_C06_GenreSearch
          genreList={genreList}
          isLoading={isGenreLoading}
          setIsLoading={setIsGenreLoading}
        />
      </div>
      <SC110_C08_CurrentEvents />
      {/* <SC110_C09_MonthlyEvents /> */}
      <SC110_C99_Footer />
    </div>
  );
};