import React, { useEffect } from 'react';
import { Search, MapPin, Calendar, Tag, ChevronRight, Smartphone, Heart, Baby, School, Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const';
import { SC110_C99_Footer } from '../SC110_Home/SC110_C99_Footer';

export const SC100_C00_LandingPage = () => {
  const navigate = useNavigate();
  const onClickLogo = () => {
    navigate(CONST_PAGE_URL.SC110_C00_Home);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full min-h-screen bg-white">
      {/* Hero Section */}
      <header className="relative w-full h-[500px] md:h-[600px] lg:h-screen overflow-hidden">
        {/* Background image container */}
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url('/pic/3670573_s.jpg')`,
            backgroundPosition: 'center 20%',
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-40" />
        </div>

        {/* Content container */}
        <div className="relative h-full flex flex-col items-center">
          {/* Logo */}
          <div className="w-full container mx-auto px-4 pt-4 lg:pt-8">
            <img
              src="/icon/logo.png"
              alt="Publiy"
              className="h-10 lg:h-16 w-auto brightness-0 invert cursor-pointer"
              onClick={onClickLogo}
            />
          </div>

          {/* Main content */}
          <div className="flex flex-col items-center justify-center w-full h-full max-w-[1440px] mx-auto px-4 text-center -mt-6 lg:-mt-4">
            {/* Text content */}
            <div className="mb-8 lg:mb-12">
              <h1 className="text-white">
                <span className="text-xl lg:text-3xl font-bold block mb-2 lg:mb-5">
                  東京23区の自治体イベントを
                </span>
                <span className="text-2xl lg:text-5xl font-bold">
                  もっと身近に、便利に
                </span>
              </h1>
            </div>

            {/* Phone mockup - Different scale for mobile and desktop */}
            <div className="relative w-[95vw] max-w-[420px] lg:w-[56vw] lg:max-w-[680px] xl:w-[52vw] xl:max-w-[850px] mt-4 lg:mt-8">
              <img
                src="/pic/mockup.jpg"
                alt="Screenshot"
                className="w-full h-auto object-contain scale-[1.4] md:scale-[1.2]"
                style={{
                  filter: 'drop-shadow(0 25px 35px rgb(0 0 0 / 0.2))'
                }}
              />
            </div>
          </div>
        </div>
      </header>



      {/* Main Content */}
      <main className="overflow-hidden">
        {/* Value Proposition */}
        <section className="py-8 lg:py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-xl lg:text-2xl font-bold text-center mb-8 lg:mb-12 max-w-2xl mx-auto">
              東京23区すべての自治体イベントを
              <br />
              ワンストップで探せるプラットフォーム
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 mb-8 lg:mb-12">
              {[
                {
                  icon: <Calendar className="w-8 h-8 lg:w-10 lg:h-10 text-pink-500" />,
                  title: "自治体のイベントを網羅",
                  description: ["23区の自治体が主催するイベントをすべて掲載。", "見逃す心配はありません。"]
                },
                {
                  icon: <MapPin className="w-8 h-8 lg:w-10 lg:h-10 text-pink-500" />,
                  title: "エリアで簡単検索",
                  description: ["住んでいる区のイベントを", "簡単に検索可能です。"]
                },
                {
                  icon: <Tag className="w-8 h-8 lg:w-10 lg:h-10 text-pink-500" />,
                  title: "ジャンル別で探せる",
                  description: ["スポーツや子育てなど、", "興味のあるジャンルから検索可能です。"]
                }
              ].map((feature, index) => (
                <div key={index} className="bg-white rounded-lg p-4 lg:p-6 shadow-lg text-center">
                  <div className="flex flex-col items-center mb-3 lg:mb-4">
                    <div className="mb-2">{feature.icon}</div>
                    <h3 className="text-base lg:text-lg font-bold">{feature.title}</h3>
                  </div>
                  <div className="text-sm lg:text-base text-gray-600">
                    {feature.description.map((line, i) => (
                      <div key={i} className="mb-1 last:mb-0">{line}</div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* User Benefits Section */}
        <section className="bg-pink-50 py-8 lg:py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-xl lg:text-2xl font-bold mb-8 lg:mb-12 text-center">Publiyでつながるイベント例</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
              <div className="bg-white p-4 lg:p-6 rounded-lg shadow-lg">
                <div className="flex flex-col items-center text-center mb-3 lg:mb-4">
                  <Baby className="w-8 h-8 lg:w-10 lg:h-10 text-pink-500 mb-2" />
                  <h3 className="text-base lg:text-lg font-bold">未就学児の保護者向け</h3>
                </div>
                <ul className="space-y-2 lg:space-y-3 text-center text-sm lg:text-base text-gray-600">
                  <li>• 親子参加の育児イベント</li>
                  <li>• 季節の手作り教室情報</li>
                  <li>• 子育て支援活動</li>
                </ul>
              </div>
              <div className="bg-white p-4 lg:p-6 rounded-lg shadow-lg">
                <div className="flex flex-col items-center text-center mb-3 lg:mb-4">
                  <School className="w-8 h-8 lg:w-10 lg:h-10 text-pink-500 mb-2" />
                  <h3 className="text-base lg:text-lg font-bold">小学生の保護者向け</h3>
                </div>
                <ul className="space-y-2 lg:space-y-3 text-center text-sm lg:text-base text-gray-600">
                  <li>• 学習支援イベント情報</li>
                  <li>• 休日の科学・工作教室</li>
                  <li>• スポーツ体験会</li>
                  <li>• 図書館のおはなし会</li>
                </ul>
              </div>
              <div className="bg-white p-4 lg:p-6 rounded-lg shadow-lg">
                <div className="flex flex-col items-center text-center mb-3 lg:mb-4">
                  <Star className="w-8 h-8 lg:w-10 lg:h-10 text-pink-500 mb-2" />
                  <h3 className="text-base lg:text-lg font-bold">シニア世代向け</h3>
                </div>
                <ul className="space-y-2 lg:space-y-3 text-center text-sm lg:text-base text-gray-600">
                  <li>• 健康講座・運動教室</li>
                  <li>• 趣味のサークル活動</li>
                  <li>• 文化活動・伝統芸能体験</li>
                  <li>• 地域交流イベント</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <SC110_C99_Footer />
    </div>
  );
};