import React from 'react';
import { Link } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const'

type Props = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

export const SC110_C11_Menu = ({ isMenuOpen, toggleMenu }: Props) => {
  return (
    <>
      {/* ハンバーガーメニューの展開部分 */}
      <div className={`
        absolute top-full right-0 bg-white shadow-lg transform transition-all duration-300 z-50
        w-48 rounded-bl-lg
        ${isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 pointer-events-none'}
      `}>
        <nav className="py-2">
          <ul className="text-sm">
            <li>
              <Link
                to={CONST_PAGE_URL.SC110_C00_Home}
                className="block px-4 py-2 text-gray-600 hover:text-pink-500 hover:bg-gray-50"
                onClick={toggleMenu}
              >
                TOP
              </Link>
            </li>
            <li>
              <Link
                to={CONST_PAGE_URL.SC100_C00_LandingPage}
                className="block px-4 py-2 text-gray-600 hover:text-pink-500 hover:bg-gray-50"
                onClick={toggleMenu}
              >
                Publiyとは
              </Link>
            </li>
            <li>
              <Link
                to={CONST_PAGE_URL.SC100_C01_Terms}
                className="block px-4 py-2 text-gray-600 hover:text-pink-500 hover:bg-gray-50"
                onClick={toggleMenu}
              >
                利用規約
              </Link>
            </li>
            <li>
              <Link
                to={CONST_PAGE_URL.SC100_C02_Contact}
                className="block px-4 py-2 text-gray-600 hover:text-pink-500 hover:bg-gray-50"
                onClick={toggleMenu}
              >
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link
                to=""
                className="block px-4 py-2 text-gray-600 hover:text-pink-500 hover:bg-gray-50"
                onClick={toggleMenu}
              >
                About us
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* 背景のオーバーレイ - メニューが小さくなったので不要かもしれません */}
      {/* {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
        />
      )} */}
    </>
  );
};