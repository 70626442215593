import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CONST_SERVICE_URI, CONST_SERVICE_RESULT_STATUS } from 'common/CM000_Const'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_ArticleInfo, SC000_SearchCondition, SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { cm060_strLog, cm060_endLog } from 'common/CM060_LogUtil';
import { requestPost } from 'common/CM070_RequestUtil'
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header';
import { SC000_C02_LoadingSpinner } from 'screens/SC000_BaseComponent/SC000_C02_LoadingSpinner';
import { SC110_C99_Footer } from 'screens/SC110_Home/SC110_C99_Footer';
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { ref, getDownloadURL } from "firebase/storage";

const SCREEN_ID = 'SC210_C00';
// プロップスの型定義
interface SC210_C01_ArticleDetailAreaProps {
  articleInfo: SC000_ArticleInfo;
}

const firebaseConfig = {
  apiKey: "AIzaSyAooIE8sL4yjJ1UU0XiL9ji1zbT9cUj2-g",
  authDomain: "jichitai-project.firebaseapp.com",
  projectId: "jichitai-project",
  storageBucket: "jichitai-project.appspot.com",
  messagingSenderId: "301926644301",
  appId: "1:301926644301:web:9be09cd99c282059cffa04",
  measurementId: "G-Y65VB3VXY4"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const fetchHTMLFile = async (filepath: string) => {
  const fileRef = ref(storage, filepath);
  try {
    const url = await getDownloadURL(fileRef);
    return url;
  } catch (error) {
    console.error("Error fetching HTML file:", error);
  }
};



// const HTMLViewer = () => {
//   const [htmlUrl, setHtmlUrl] = useState<string>('');

//   useEffect(() => {
//     const loadHTML = async () => {
//       const url = await fetchHTMLFile();
//       if (url) { // urlがundefinedでないことを確認
//         console.log('HTMLViewer_url:', url)
//         setHtmlUrl(url);
//       } else {
//         console.error("urlがundefined");
//       }
//     };
//     loadHTML();
//   }, []);

//   return (
//     <div>
//       {htmlUrl ? (
//         <iframe src={htmlUrl} width="100%" height="500px" title="HTML Viewer"></iframe>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// };

const HTMLViewer2 = ({ articleInfo }: SC210_C01_ArticleDetailAreaProps) => {
  const [htmlUrl, setHtmlUrl] = useState<string>('');

  useEffect(() => {
    const loadHTML = async () => {
      const url = await fetchHTMLFile(articleInfo.articleHtmlFilepath);
      if (url) { // urlがundefinedでないことを確認
        console.log('url:', url)
        setHtmlUrl(url);
      } else {
        console.error("urlがundefined");
      }
    };
    loadHTML();
  }, []);

  return (
    <div>
      {htmlUrl ? (
        <iframe src={htmlUrl} width="100%" height="500px" title="HTML Viewer"></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

// const HTMLViewer3 = () => {
//   const filepath = 'test/article/6ya0FWOZ8vnAhDK9src7/6ya0FWOZ8vnAhDK9src7.html'
//   const { getStorage, getDownloadURL } = require('firebase-admin/storage');
//   const fileRef = getStorage().bucket('jichitai-project.appspot.com').file(filepath);

//   const [htmlUrl, setHtmlUrl] = useState<string>('');

//   useEffect(() => {
//     const loadHTML = async () => {
//       const url = await getDownloadURL(fileRef);
//       if (url) { // urlがundefinedでないことを確認
//         console.log('url:', url)
//         setHtmlUrl(url);
//       } else {
//         console.error("urlがundefined");
//       }
//     };
//     loadHTML();
//   }, []);

//   return (
//     <div>
//       {htmlUrl ? (
//         <iframe src={htmlUrl} width="100%" height="500px" title="HTML Viewer"></iframe>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// };

export const SC210_C01_ArticleDetailArea = ({ articleInfo }: SC210_C01_ArticleDetailAreaProps) => {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog(SCREEN_ID, 'SC999_C02_Article')
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  // 終了ログ
  cm060_endLog(SCREEN_ID, 'SC999_C02_Article')
  // ----------------------------------------------------------------------
  return (
    <>
      {/* <HTMLViewer /> */}
      <HTMLViewer2 articleInfo={articleInfo} />
      {/* <HTMLViewer3 /> */}
    </>
  );
};