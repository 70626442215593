import React, { useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const'
import { SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { SC000_C02_LoadingSpinner } from 'screens/SC000_BaseComponent/SC000_C02_LoadingSpinner';
import { getTodayYyyyMmDd } from 'common/CM050_DateUtil'

interface SC110_C06_GenreSearch_Props {
  genreList: SC000_Genre[];
  isLoading?: boolean;
  setIsLoading?: (loading: boolean) => void;
}

export const SC110_C06_GenreSearch: React.FC<SC110_C06_GenreSearch_Props> = ({
  genreList,
  isLoading = false,
  setIsLoading = () => { },
}) => {
  // ナビゲーター関数を取得
  const navigate = useNavigate();
  // リクエストパラメータを取得
  const [searchParams, setSearchParams] = useSearchParams();

  // データ読み込み用のEffect
  useEffect(() => {
    const loadGenreData = async () => {
      setIsLoading(true);
      try {
        // 画像の先読みなど、必要な初期化処理があればここで実行
        await Promise.all(
          genreList.map((genre) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = `/genre/${genre.genreId}.jpg`;
              img.onload = resolve;
              img.onerror = resolve; // エラー時も続行
            });
          })
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadGenreData();
  }, [genreList, setIsLoading]);

  // エリア画像クリック時のイベントハンドラ
  const onClickGenreImage = (genreId: string) => {
    // パラメータを初期化する
    const newSearchParams = new URLSearchParams();
    // リクエストパラメータを定義する
    newSearchParams.append('strDate', getTodayYyyyMmDd('YYYY-MM-DD'));
    newSearchParams.append('endDate', '');
    newSearchParams.append('genreList', genreId);
    newSearchParams.append('keyword', '');
    // 画面を遷移する
    navigate(CONST_PAGE_URL.SC130_C00_SearchResult + `?${newSearchParams.toString()}`);
  }

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-white">
      <h3 className="text-lg md:text-xl font-semibold mb-4 md:mb-6 text-gray-700 px-4 sm:px-6 lg:px-8">
        ジャンルから探す
      </h3>
      <div className="relative h-[160px] md:h-[200px]"> {/* 高さを固定 */}
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <SC000_C02_LoadingSpinner
              size={8}
              color="bg-gray-400"
              className="transform -translate-y-4" // 位置を少し上に調整
            />
          </div>
        ) : (
          <>
            <button
              onClick={() => scroll('left')}
              className="hidden md:block absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-2 md:p-3 shadow-neu-button opacity-50 hover:opacity-100 transition-opacity"
              aria-label="左にスクロール"
              style={{ left: '4px' }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L6 10L12 16" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div
              ref={scrollContainerRef}
              className="flex overflow-x-auto space-x-4 md:space-x-6 pb-4 md:pb-6 scrollbar-hide"
              style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
            >
              {genreList.map((genre) => (
                <div
                  key={genre.genreId}
                  className="flex flex-col items-center space-y-2 md:space-y-3 flex-shrink-0 w-24 md:w-32 pl-4 sm:pl-6 lg:pl-8 first:pl-4 sm:first:pl-6 lg:first:pl-8"
                >
                  <div
                    className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-neu-button overflow-hidden bg-white flex items-center justify-center cursor-pointer"
                    onClick={() => onClickGenreImage(genre.genreId)}
                  >
                    <img
                      src={`/genre/${genre.genreId}.jpg`}
                      alt={`${genre.genreName}の画像`}
                      className="w-full h-full object-cover bg-white"
                    />
                  </div>
                  <span className="text-sm md:text-base text-gray-600 text-center w-full truncate">
                    {genre.genreName}
                  </span>
                </div>
              ))}
            </div>
            <button
              onClick={() => scroll('right')}
              className="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full p-2 md:p-3 shadow-neu-button opacity-50 hover:opacity-100 transition-opacity"
              aria-label="右にスクロール"
              style={{ right: '4px' }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 4L14 10L8 16" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </>
        )}
      </div>
    </div>
  );
};