import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONST_PAGE_URL } from 'common/CM000_Const'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { cm010_getImageUrl } from 'common/CM010_AppUtil'
import { convertYyyyMmDdToYyyy_Mm_Dd } from 'common/CM050_DateUtil'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_Event, SC000_SearchCondition, SC000_OrderByInfo } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { getEventList } from 'screens/SC130_SearchResult/SC130_C00_SearchResult';

interface EventTableProps {
  eventList: SC000_Event[];
  title: string;
}

interface EventTableContentsProps {
  eventList: SC000_Event[];
  handleEventClick: (eventId: string) => void;
  getMunicipalityName: (municipalityId: string) => string;
  getGenreName: (genreId: string) => string
}

export const useEventTable = (SCREEN_ID: string) => {
  // 1. 各種フックを取得する
  // 1.2. ステートを取得する
  const [eventListState, setEventListState] = useState<SC000_Event[]>([])

  // 初期ロード時に実行する関数
  const initComponent = async (
    strDate: string,
    endDate: string,
    municipalitiesList: string[],
    genreList: string[],
    keyword: string,
    orderByList: SC000_OrderByInfo[],
    limit: number,
  ) => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(SCREEN_ID, 'initComponent')
    // ----------------------------------------------------------------------
    // 3. 指定されたパラメータで検索する
    // リクエストパラメータ用の検索条件の作成
    const searchCondition: SC000_SearchCondition = {
      strDate: strDate,
      endDate: endDate,
      municipalitiesList: municipalitiesList,
      genreList: genreList,
      keyword: keyword,
      orderByList: orderByList,
      limit: limit,
    }
    // イベントリストを取得し、ステートを更新する関数（非同期処理を実装するため関数個別に関数化）
    const updateEventListView = async () => {
      // DB検索し、イベントリストを取得
      const eventList = await getEventList(searchCondition)
      // イベントリストでステート更新
      setEventListState(eventList)
    }
    // 関数の実行
    updateEventListView()
    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(SCREEN_ID, 'initComponent')
    // ----------------------------------------------------------------------
  }

  return {
    eventListState: eventListState,
    initComponent: initComponent,
  }
}

export const EventTable: React.FC<EventTableProps> = ({ eventList, title }) => {
  // 各種フックを取得する
  // コンテキスト
  const { state: baseState } = useContext(SC000_S_Context);
  // ナビゲーターを取得
  const navigate = useNavigate();
  // イベントクリック時のイベントハンドラ関数
  const handleEventClick = (eventId: string) => {
    navigate(CONST_PAGE_URL.SC140_C00_SearchResultDetail + `/${eventId}`);
  };
  // 東京都の市区町村リストを取得
  const getMunicipalitiesList = () => {
    const prefecture = baseState.prefecturesList.find(
      pref => pref.prefecturesId === '13_TOKYO'
    );
    return prefecture ? prefecture.municipalitiesList : [];
  };
  const tokyoMunicipalitiesList = getMunicipalitiesList();
  // 市区町村IDから区名を取得する関数
  const getMunicipalityName = (municipalityId: string) => {
    const municipality = tokyoMunicipalitiesList.find(
      m => m.municipalitiesId === municipalityId
    );
    return municipality ? municipality.municipalitiesName : '';
  };
  // ジャンルIDから日本語名を取得する関数
  const genreList = baseState.genreList;
  const getGenreName = (genreId: string) => {
    const genre = genreList.find(g => g.genreId === genreId);
    return genre ? genre.genreName : genreId;
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-neu">
      <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-gray-700">{title}</h3>
      <div className="overflow-x-auto">
        <EventTableContents2 eventList={eventList} handleEventClick={handleEventClick} getMunicipalityName={getMunicipalityName} getGenreName={getGenreName} />
      </div>
    </div>
  );
};

export const EventTableContents1: React.FC<EventTableContentsProps> = ({ eventList, handleEventClick, getMunicipalityName }) => {
  return (
    <>
      <table className="w-full">
        <thead>
          <tr className="border-b border-gray-200">
            <th className="py-2 px-2 md:py-3 md:px-4 text-left text-xs md:text-base font-medium text-gray-600">イベント名</th>
            {/* <th className="py-2 px-1 md:py-3 md:px-4 text-center text-xs md:text-base font-medium text-gray-600">市区町村</th> */}
            <th className="py-2 px-1 md:py-3 md:px-4 text-center text-xs md:text-base font-medium text-gray-600">開始</th>
            <th className="py-2 px-1 md:py-3 md:px-4 text-center text-xs md:text-base font-medium text-gray-600">終了</th>
          </tr>
        </thead>
        <tbody>
          {eventList.map((event) => (
            <tr key={event.eventId} className="border-b border-gray-100 cursor-pointer hover:bg-gray-50" onClick={() => { handleEventClick(event.eventId) }}>
              <td className="py-2 px-2 md:py-3 md:px-4 text-sm md:text-base text-gray-800">
                {event.eventName}<br /><span className="inline-block bg-gray-100 text-gray-600 text-xs md:text-sm px-2 py-1 rounded-md mb-1">
                  {getMunicipalityName(event.municipalitiesId)}
                </span>
              </td>
              {/* <td className="py-2 px-1 md:py-3 md:px-4 text-xs md:text-base text-gray-600 whitespace-nowrap">{getMunicipalityName(event.municipalitiesId)}</td> */}
              <td className="py-2 px-1 md:py-3 md:px-4 text-xs md:text-base text-gray-600 whitespace-nowrap">{convertYyyyMmDdToYyyy_Mm_Dd(event.eventStrDate, '$2/$3')}</td>
              <td className="py-2 px-1 md:py-3 md:px-4 text-xs md:text-base text-gray-600 whitespace-nowrap">{convertYyyyMmDdToYyyy_Mm_Dd(event.eventEndDate, '$2/$3')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export const EventTableContents2: React.FC<EventTableContentsProps> = ({ eventList, handleEventClick, getMunicipalityName, getGenreName }) => {
  return (
    <>
      <div className="divide-y">
        {eventList.map((event: SC000_Event) => {
          const municipalityName = getMunicipalityName(event.municipalitiesId);
          const startDate = convertYyyyMmDdToYyyy_Mm_Dd(event.eventStrDate, '$2/$3')
          const endDate = convertYyyyMmDdToYyyy_Mm_Dd(event.eventEndDate, '$2/$3');

          return (
            <div
              key={event.eventId}
              className="p-4 cursor-pointer hover:bg-gray-50 transition-colors duration-150"
              onClick={() => handleEventClick(event.eventId)}
            >
              <div className="flex">
                <img
                  src={cm010_getImageUrl(event.eventImageUrl, event.eventImageOriginUrl)}
                  alt={event.eventName}
                  className="w-24 h-24 object-cover rounded"
                />
                <div className="ml-4 flex-1">
                  {/* <h2 className="text-lg font-semibold">{event.eventName}</h2> */}
                  <p className="text-sm font-semibold">{event.eventName}</p>

                  {/* タグエリア */}
                  <div className="flex flex-wrap gap-1.5 mt-1.5 items-center">
                    {/* 区のタグ */}
                    {municipalityName && (
                      <span className="px-2 py-0.5 text-xs bg-blue-25 text-gray-600 rounded">
                        {municipalityName}
                      </span>
                    )}

                    {/* ジャンルタグ */}
                    {event.eventGenreIdList && event.eventGenreIdList.map((genreId, index) => (
                      <span
                        key={index}
                        className="px-2 py-0.5 text-xs bg-gray-25 text-gray-600 rounded"
                      >
                        {getGenreName(genreId)}
                      </span>
                    ))}
                  </div>

                  {/* イベント期間 */}
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      開催日：{startDate} ～ {endDate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

// export default EventTable;

