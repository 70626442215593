import axios from 'axios';
import React, { useState, useContext, memo, useEffect } from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Grid, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { dateToString } from 'common/CM050_DateUtil'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_UPDATE_SEARCH_CONDITION } from 'screens/SC000_BaseComponent/SC000_000_Action'
import { SC000_SearchCondition } from 'screens/SC000_BaseComponent/SC000_000_Types'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { ref, getDownloadURL } from "firebase/storage";

const SCREEN_ID = 'SC999_C02'

const municipalities = ['Tokyo', 'Osaka', 'Kyoto']; // 仮のデータ
const genres = ['Music', 'Sports', 'Technology']; // 仮のデータ

// イベントの型定義
type Event = {
    eventId: string;
    eventName: string;
    eventStrDate: string;
    eventEndDate: string;
};

const firebaseConfig = {
    apiKey: "AIzaSyAooIE8sL4yjJ1UU0XiL9ji1zbT9cUj2-g",
    authDomain: "jichitai-project.firebaseapp.com",
    projectId: "jichitai-project",
    storageBucket: "jichitai-project.appspot.com",
    messagingSenderId: "301926644301",
    appId: "1:301926644301:web:9be09cd99c282059cffa04",
    measurementId: "G-Y65VB3VXY4"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const fetchHTMLFile = async () => {
    const fileRef = ref(storage, '/test/article/spring_events_2024/spring_events_2024.html');
    try {
        const url = await getDownloadURL(fileRef);
        return url;
    } catch (error) {
        console.error("Error fetching HTML file:", error);
    }
};

const HTMLViewer = () => {
    const [htmlUrl, setHtmlUrl] = useState<string>('');

    useEffect(() => {
        const loadHTML = async () => {
            const url = await fetchHTMLFile();
            if (url) { // urlがundefinedでないことを確認
                setHtmlUrl(url);
            }
        };
        loadHTML();
    }, []);

    return (
        <div>
            {htmlUrl ? (
                <iframe src={htmlUrl} width="100%" height="500px" title="HTML Viewer"></iframe>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export const SC999_C02_Article = () => {
    // ----------------------------------------------------------------------
    // 開始ログ
    cm060_strLog(SCREEN_ID, 'SC999_C02_Article')
    // ----------------------------------------------------------------------

    // ----------------------------------------------------------------------
    // 終了ログ
    cm060_endLog(SCREEN_ID, 'SC999_C02_Article')
    // ----------------------------------------------------------------------
    return (
        <>
            SC999_C02_Article
            <HTMLViewer />

        </>
    );
};
