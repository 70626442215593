import type {
    SC000_Context,
    SC000_Action,
    SC000_Prefectures,
    SC000_Genre,
    SC000_SearchCondition,
} from 'screens/SC000_BaseComponent/SC000_000_Types'
import { SC000_ACTIONTYPE } from 'screens/SC000_BaseComponent/SC000_000_Action'

// SC999_Reducer.tsには、基本的に「reducerメソッド」のみを定義する。
// reducerメソッドの中にアクションごとの処理を記載する。

// Actionから渡された更新情報を使って、新しいstate情報を作るためのメソッド
export const reducer = (state: SC000_Context, action: SC000_Action) => {
    // 更新後state情報を宣言する（初期値として更新前state情報を設定する）
    let newState = { ...state }
    // actionオブジェクトからtypeとpayloadを取得する
    const { type, payload } = action
    // ActionTypeごとに処理を分岐する
    switch (type) {
        // CHANGE_SCREENの場合
        case SC000_ACTIONTYPE.SC000.SC000_CHANGE_SCREEN:
            newState = { ...state, ...payload }
            break
        case SC000_ACTIONTYPE.SC000.SC000_UPDATE_LOGIN_USER:
            newState = { ...state, ...payload }
            break
        case SC000_ACTIONTYPE.SC000.SC000_UPDATE_LAYOUTPATTERN:
            newState = { ...state, ...payload }
            break
        case SC000_ACTIONTYPE.SC000.SC000_UPDATE_SEARCH_CONDITION:
            const searchConditionPayload = payload as SC000_SearchCondition
            newState = { ...state, searchCondition: searchConditionPayload }
            break
        case SC000_ACTIONTYPE.SC000.SC000_UPDATE_PREFECTURES_LIST:
            // newState = { ...state, ...payload } の部分で、payload がオブジェクトとして扱われ、そのプロパティが newState に展開される。
            // payload が配列である場合、その要素がオブジェクトのキーとして扱われてしまい、意図しないオブジェクトが生成される。
            // このため、prefecturesList を直接 payload で置き換える
            const prefecturesListPayload = payload as SC000_Prefectures[]
            newState = { ...state, prefecturesList: prefecturesListPayload }
            break
        case SC000_ACTIONTYPE.SC000.SC000_UPDATE_GENRE:
            const genreListPayload = payload as SC000_Genre[]
            newState = { ...state, genreList: genreListPayload }
            break
        // SC110_コンテキスト全更新
        case SC000_ACTIONTYPE.SC110.SC110_UPDATE_HOME:
            newState = { ...state, ...{ baseContext_SC210: payload } }
            break
    }
    // //console.log("SC010_Reducer,reducer.action:", action,)
    // //console.log("SC010_Reducer,reducer.state:", state,)
    // //console.log("SC010_Reducer,reducer.newState:", newState)
    return newState
}