// Actionを定義。typeとpayloadを指定する。

import type {
    SC000_ScreenController,
    SC000_LoginUserInfo,
    SC000_ErrorInfo,
    SC000_SearchCondition,
    SC000_Prefectures,
    SC000_Genre,
    SC110_BaseContext,
} from 'screens/SC000_BaseComponent/SC000_000_Types'
// import { SC000_ACTIONTYPE } from '../../common/C000_Const'

// ①ActionTypeを定義
// ================================================================================
export const SC000_ACTIONTYPE = {
    SC000: {
        SC000_CHANGE_SCREEN: 'SC000_CHANGE_SCREEN',
        SC000_UPDATE_LOGIN_USER: 'SC000_UPDATE_LOGIN_USER',
        SC000_UPDATE_LAYOUTPATTERN: 'SC000_UPDATE_LAYOUTPATTERN',
        SC000_ERROR: 'SC000_ERROR',
        SC000_UPDATE_SEARCH_CONDITION: 'SC000_UPDATE_SEARCH_CONDITION',
        SC000_UPDATE_PREFECTURES_LIST: 'SC000_UPDATE_PREFECTURES_LIST',
        SC000_UPDATE_GENRE: 'SC000_UPDATE_GENRE',
    },
    SC110: {
        SC110_UPDATE_HOME: 'SC110_UPDATE_HOME',
    }
}
// ================================================================================

// ②Actionを定義
// ================================================================================
// SC000---------------------------------------------------------------------------
export const SC000_CHANGE_SCREEN = (payload: SC000_ScreenController) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_CHANGE_SCREEN,
        payload
    }
}
export const SC000_UPDATE_LOGIN_USER = (payload: SC000_LoginUserInfo) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_UPDATE_LOGIN_USER,
        payload
    }
}
export const SC000_UPDATE_LAYOUTPATTERN = (payload: SC000_ScreenController) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_UPDATE_LAYOUTPATTERN,
        payload
    }
}
export const SC000_ERROR = (payload: SC000_ErrorInfo) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_ERROR,
        payload
    }
}
export const SC000_UPDATE_SEARCH_CONDITION = (payload: SC000_SearchCondition) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_UPDATE_SEARCH_CONDITION,
        payload
    }
}
export const SC000_UPDATE_PREFECTURES_LIST = (payload: SC000_Prefectures[]) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_UPDATE_PREFECTURES_LIST,
        payload
    }
}
export const SC000_UPDATE_GENRE_LIST = (payload: SC000_Genre[]) => {
    return {
        type: SC000_ACTIONTYPE.SC000.SC000_UPDATE_GENRE,
        payload
    }
}
export const SC110_UPDATE_HOME_INFO = (payload: SC110_BaseContext) => {
    return {
        type: SC000_ACTIONTYPE.SC110.SC110_UPDATE_HOME,
        payload
    }
}
// --------------------------------------------------------------------------------
// ================================================================================


