import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="lg:max-w-screen-2xl lg:mx-auto">
        <div className="lg:flex">
          {/* 左側の余白（PCのみ表示） */}
          <div className="hidden lg:block lg:w-1/6">
            {/* 将来的に広告などを配置 */}
          </div>

          {/* メインコンテンツ */}
          <main className="w-full lg:w-2/3">
            {/* PCの場合のみpx-0を適用し、スマホでは余白なし */}
            <div className="lg:px-0">
              {children}
            </div>
          </main>

          {/* 右側の余白（PCのみ表示） */}
          <div className="hidden lg:block lg:w-1/6">
            {/* 将来的に広告などを配置 */}
          </div>
        </div>
      </div>
    </div>
  );
};