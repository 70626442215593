import React, { useEffect } from 'react';
import { Mail } from 'lucide-react';
import { SC110_C99_Footer } from '../SC110_Home/SC110_C99_Footer';
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header';

export const SC100_C02_Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
      
    return (
        <div className="min-h-screen bg-white">
            <SC110_C01_Header />

            <main className="container mx-auto px-4 py-8 md:py-12">
                <h1 className="text-2xl md:text-3xl font-bold mb-8 text-center">お問い合わせ</h1>

                <div className="max-w-2xl mx-auto">
                    <div className="mb-8">
                        <p className="text-gray-600 mb-4">
                            Publiyに関するお問い合わせは、以下のメールアドレスまでご連絡ください。
                        </p>
                        <p className="text-gray-600 mb-8">
                            内容を確認の上、順次回答させていただきます。
                        </p>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-lg mb-8">
                        <div className="flex items-center space-x-2 text-lg font-medium text-gray-700">
                            <Mail className="w-6 h-6" />
                            <a href="mailto:publiy_official@wktllc.jp" className="text-pink-500 hover:text-pink-600">
                                publiy_official@wktllc.jp
                            </a>
                        </div>
                    </div>

                    <div className="space-y-4 text-gray-600">
                        <h3 className="font-bold text-gray-700">お問い合わせの際は、以下の項目を記載いただけますとスムーズです：</h3>
                        <ul className="list-disc list-inside space-y-2 pl-4">
                            <li>お名前</li>
                            <li>ご連絡先メールアドレス</li>
                            <li>お問い合わせの種類
                                <ul className="list-none pl-4 pt-2 space-y-1">
                                    <li>・サービスについて</li>
                                    <li>・イベント情報について</li>
                                    <li>・その他</li>
                                </ul>
                            </li>
                            <li>お問い合わせ内容の詳細</li>
                        </ul>
                    </div>

                    <div className="mt-8 pt-6 border-t">
                        <h3 className="font-bold text-gray-700 mb-4">ご注意事項：</h3>
                        <ul className="text-gray-600 space-y-2">
                            <li>・土日祝日、年末年始のお問い合わせは、翌営業日以降の対応となります。</li>
                            <li>・お問い合わせの内容によっては、回答までにお時間をいただく場合がございます。</li>
                            <li>・迷惑メール対策などでドメイン指定を行っている場合は、「@wktllc.jp」からのメールを受信できるように設定をお願いいたします。</li>
                        </ul>
                    </div>
                </div>
            </main>

            <SC110_C99_Footer />
        </div>
    );
};