// 今日の日付を取得
export const getTodayYyyyMmDd = (format: string = 'YYYYMMDD') => {
    //今日の情報を取得
    const today = new Date();
    // YYYYMMDDにフォーマット
    const todayYyyyMmDd = dateToString(today, format);
    // 戻り値を返却
    return todayYyyyMmDd
}

export const getMonthStrAndEndDatesYyyyMmDd = (): { mnothStrDate: string; monthEndDate: string } => {
    // 今日の日付を取得
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    // 今月の最初の日
    const mnothStrDate = new Date(year, month, 1);
    // 来月の最初の日を取得し、1日引くことで今月の最終日を取得
    const nextMonthStrtDate = new Date(year, month + 1, 1);
    const monthEndDate = new Date(nextMonthStrtDate.getTime() - 24 * 60 * 60 * 1000);
    // フォーマット
    const mnothStrDateYyyyMmDd = dateToString(mnothStrDate, 'YYYYMMDD')
    const mnothEndDateYyyyMmDd = dateToString(monthEndDate, 'YYYYMMDD')

    return { mnothStrDate: mnothStrDateYyyyMmDd, monthEndDate: mnothEndDateYyyyMmDd };
}


//日付から文字列に変換する関数(0埋めあり)
export const dateToString = (date: Date, format_str: string) => {

    const year_str = String(date.getFullYear());
    //月だけ+1すること
    let month_str = String(1 + date.getMonth());
    let day_str = String(date.getDate());
    let hour_str = String(date.getHours());
    let minute_str = String(date.getMinutes());
    let second_str = String(date.getSeconds());

    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);
    hour_str = ('0' + hour_str).slice(-2);
    minute_str = ('0' + minute_str).slice(-2);
    second_str = ('0' + second_str).slice(-2);

    // let format_str = 'YYYY-MM-DD hh:mm:ss';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str;
};

//日付から文字列に変換する関数(0埋めなし)
export const dateToString_NoZero = (date: Date, format_str: string) => {

    const year_str = String(date.getFullYear());
    //月だけ+1すること
    const month_str = String(1 + date.getMonth());
    const day_str = String(date.getDate());
    const hour_str = String(date.getHours());
    const minute_str = String(date.getMinutes());
    const second_str = String(date.getSeconds());


    // let format_str = 'YYYY-MM-DD hh:mm:ss';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str;
};

export const convertYyyy_Mm_DdToYYYYMMDD = (dateYyyy_Mm_Dd: string): string => {
    // yyyy/mm/dd形式の文字列をyyyy-mm-dd形式に変換
    return dateYyyy_Mm_Dd.replace(/-/g, '');
}

export const convertYyyyMmDdToYyyy_Mm_Dd = (
    dateYyyyMmDd: string,
    format: string = '$1-$2-$3'
): string => {
    // YYYYMMDD形式の文字列を指定されたフォーマットに変換
    return dateYyyyMmDd.replace(/(\d{4})(\d{2})(\d{2})/, format);
}

export const convertYyyyMmDdStringToDate = (yyyymmddString: string): Date => {
    // 年月日を抽出する
    const year = Number(yyyymmddString.slice(0, 4));
    const month = Number(yyyymmddString.slice(4, 6)) - 1; // 月は0から始まるため-1する
    const day = Number(yyyymmddString.slice(6));
    // 日付を生成する
    return new Date(year, month, day);
}