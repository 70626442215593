import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAooIE8sL4yjJ1UU0XiL9ji1zbT9cUj2-g",
    authDomain: "jichitai-project.firebaseapp.com",
    projectId: "jichitai-project",
    storageBucket: "jichitai-project.appspot.com",
    messagingSenderId: "301926644301",
    appId: "1:301926644301:web:9be09cd99c282059cffa04",
    measurementId: "G-Y65VB3VXY4"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const getDownloadUrlFromStorageFile = async (storageFilepath: string) => {
    console.log('storageFilepath:', storageFilepath)
    const fileRef = ref(storage, storageFilepath);
    try {
        const url = await getDownloadURL(fileRef);
        console.log('url:', url)
        return url;
    } catch (error) {
        console.error("Error fetching HTML file:", error);
    }
};