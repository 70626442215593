import React, { useEffect } from 'react';
import { Search } from 'lucide-react';
import { SC120_C00_SearchFilter } from 'screens/SC120_Search/SC120_C00_SearchFilter';
import { SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';

interface SC110_C10_SearchFilterDisplay {
  areaList: SC000_Municipalities[];
  genreList: SC000_Genre[];
  isPopupOpen: boolean;
  togglePopup: () => any;
}

export const SC110_C10_SearchFilterDisplay: React.FC<SC110_C10_SearchFilterDisplay> = ({ 
  areaList, 
  genreList, 
  isPopupOpen, 
  togglePopup 
}) => {
  // モーダルが開いている時のbodyスタイル制御
  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.position = '';
      document.body.style.width = '';
    }
    return () => {
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [isPopupOpen]);

  if (!isPopupOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      style={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        WebkitTextSizeAdjust: '100%',
      }}
    >
      <div 
        className="relative w-full max-w-md mx-auto"
        style={{
          transform: 'scale(1)',
          WebkitTransform: 'scale(1)',
          maxHeight: '90vh',
        }}
      >
        <SC120_C00_SearchFilter 
          areaList={areaList} 
          genreList={genreList} 
          onClose={togglePopup} 
        />
      </div>
    </div>
  );
};