import React from 'react';
import { convertYyyy_Mm_DdToYYYYMMDD, convertYyyyMmDdToYyyy_Mm_Dd } from 'common/CM050_DateUtil'

interface SC120_C01_DateSearchProps {
  strDate: string;
  endDate: string;
  handleStrDateForm: (date: string) => void;
  handleEndDateForm: (date: string) => void;
}

export const SC120_C01_DateSearch: React.FC<SC120_C01_DateSearchProps> = ({
  strDate, endDate, handleStrDateForm, handleEndDateForm
}) => {
  // 開始日フォームの変更を契機に実行する関する
  const onChangeStrDate = (strDateFormValue: string) => {
    // フォーマット変換
    console.log('strDateFormValue:', strDateFormValue)
    const strDate = convertYyyy_Mm_DdToYYYYMMDD(strDateFormValue)
    console.log('strDate:', strDate)
    // コンテキストの変更
    handleStrDateForm(strDate)
  }
  // 終了日フォームの変更を契機に実行する関する
  const onChangeEndDate = (endDateFormValue: string) => {
    // フォーマット変換
    const endDate = convertYyyy_Mm_DdToYYYYMMDD(endDateFormValue)
    // コンテキストの変更
    handleEndDateForm(endDate)
  }
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">日付検索</h3>
      <div className="flex space-x-2">
        <input
          type="date"
          value={convertYyyyMmDdToYyyy_Mm_Dd(strDate)}
          onChange={(e) => onChangeStrDate(e.target.value)}
          className="border rounded p-2 w-1/2"
        />
        <span className="self-center">〜</span>
        <input
          type="date"
          value={convertYyyyMmDdToYyyy_Mm_Dd(endDate)}
          onChange={(e) => onChangeEndDate(e.target.value)}
          className="border rounded p-2 w-1/2"
        />
      </div>
    </div>
  );
};