import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CONST_SERVICE_URI, CONST_SERVICE_RESULT_STATUS } from 'common/CM000_Const'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_ArticleInfo, SC000_SearchCondition, SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { cm060_strLog, cm060_endLog } from 'common/CM060_LogUtil';
import { requestPost } from 'common/CM070_RequestUtil'
import { SC110_C01_Header } from 'screens/SC110_Home/SC110_C01_Header';
import { SC210_C01_ArticleDetailArea } from './SC210_C01_ArticleDetailArea';
import { SC000_C02_LoadingSpinner } from 'screens/SC000_BaseComponent/SC000_C02_LoadingSpinner';
import { SC110_C99_Footer } from 'screens/SC110_Home/SC110_C99_Footer';

const SCREEN_ID = 'SC140_C00';

const DEFAULT_ARTICLE_INFO = {
  _docId: '',
  articleId: '',
  articleTitle: '',
  articleAbout: '',
  articleHtmlFilepath: '',
  articleHtmlDLUrl: '',
  articleMainImageFilepath: '',
  articleMainImageDLUrl: '',
  prefecturesIdList: [],
  municipalitiesIdList: [],
  articleGenreIdList: [],
  articleKbn: '',
}

// イベント詳細取得
const getArticleDetailInfo = async (articleId: string): Promise<SC000_ArticleInfo> => {
  // ----------------------------------------------------------------------
  cm060_strLog(SCREEN_ID, 'getArticleDetailInfo')
  // ----------------------------------------------------------------------
  // リクエスト呼び出し(SR0130)
  const searchConditionOnlyId = {
    articleId: articleId,
  }
  const response_330 = await requestPost(CONST_SERVICE_URI.SR0330, searchConditionOnlyId)
  const articleDetailInfo = response_330.data.result.articleDetailInfo || {}
  console.log('articleDetailInfo:', articleDetailInfo)
  // ----------------------------------------------------------------------
  // 終了ログ
  cm060_endLog(SCREEN_ID, 'getArticleDetailInfo')
  // ----------------------------------------------------------------------
  // 戻り値を返却
  return articleDetailInfo
}

export const SC210_C00_ArticleDetail = () => {
  // ----------------------------------------------------------------------
  // 開始ログ
  cm060_strLog(SCREEN_ID, 'SC210_C00_ArticleDetail');
  // ----------------------------------------------------------------------
  // 1. 各種フックを取得する
  // 1.1. コンテキストを取得する
  const { state: baseState, dispatch: baseDispatch } = useContext(SC000_S_Context)
  // 1.2. ステートを取得する
  const [articleDetailInfo, setArticleDetailInfoState] = useState<SC000_ArticleInfo>(DEFAULT_ARTICLE_INFO)
  const [isLoading, setIsLoading] = useState(true)  // ローディング状態追加
  // 1.3. リクエストパラメータを取得する
  let { articleId } = useParams<{ articleId: string }>();

  // 初期ロード時に実行する関数
  const initComponent = async () => {
    // ----------------------------------------------------------------------
    cm060_strLog(SCREEN_ID, 'initComponent')
    // ----------------------------------------------------------------------
    // ローディング開始
    setIsLoading(true)
    try {
      // 2. リクエストパラメータを取得する(...|| ''で、nullだったら空文字を設定するようにする)
      articleId = articleId || '';
      // 3. 指定されたパラメータで検索する
      // イベントリストを取得し、ステートを更新する関数（非同期処理を実装するため関数個別に関数化）
      // DB検索し、イベントリストを取得
      const articleDetailInfo = await getArticleDetailInfo(articleId)
      // イベントリストでステート更新
      setArticleDetailInfoState(articleDetailInfo)
    } catch (error) {
      console.error('Failed to fetch article detail:', error)
    } finally {
      // ローディング終了
      setIsLoading(false)
    }
    // ----------------------------------------------------------------------
    cm060_endLog(SCREEN_ID, 'initComponent')
    // ----------------------------------------------------------------------
  }
  // 初期処理を実行
  useEffect(() => {
    initComponent();
  }, []);
  // ----------------------------------------------------------------------
  cm060_endLog(SCREEN_ID, 'SC210_C00_ArticleDetail')
  // ----------------------------------------------------------------------
  return (
    <div className='SC140_SearchResultDetail'>
      <SC110_C01_Header />
      {isLoading ? (
        <div className="flex items-center justify-center h-[200px]">
          <SC000_C02_LoadingSpinner
            size={8}
            color="bg-gray-400"
          />
        </div>
      ) : (
        <SC210_C01_ArticleDetailArea articleInfo={articleDetailInfo} />
      )}
      <SC110_C99_Footer />
    </div>
  );
};

export default SC210_C00_ArticleDetail;