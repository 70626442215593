import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { CONST_PAGE_URL } from 'common/CM000_Const'
import { cm060_strLog, cm060_endLog, cm060_debugLog } from 'common/CM060_LogUtil'
import { SC000_S_Context } from 'screens/SC000_BaseComponent/SC000_000_Store'
import { SC000_Municipalities, SC000_Genre } from 'screens/SC000_BaseComponent/SC000_000_Types';
import { SC110_C10_SearchFilterDisplay } from 'screens/SC110_Home/SC110_C10_SearchFilterDisplay';
import { SC110_C11_Menu } from './SC110_C11_Menu';

export const SC110_C01_Header = () => {
  // 1. ベースコンテキストを取得する
  const { state: baseState } = useContext(SC000_S_Context)

  // 2. 東京都の市区町村情報の取得
  const prefecturesList = baseState.prefecturesList
  let tokyoMunicipalitiesList = [] as SC000_Municipalities[]
  prefecturesList.map((prefectures) => {
    if (prefectures.prefecturesId === '13_TOKYO') {
      tokyoMunicipalitiesList = prefectures.municipalitiesList
    }
  })

  // 3. ジャンル情報の取得
  const genreList = baseState.genreList

  const navigate = useNavigate();
  const onClickLogo = () => {
    navigate(CONST_PAGE_URL.SC110_C00_Home);
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  };

  // ハンバーガーメニューの状態管理
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white relative">
      <div className="w-full px-4 md:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16 md:h-18 lg:h-20">
          <div className="w-8 h-8 md:w-10 md:h-10 invisible">
            {/* Layout balance spacer */}
          </div>

          <div className="flex justify-center flex-grow">
            <img
              src="/icon/logo.png"
              alt="Publiy Logo"
              className="h-8 md:h-10 lg:h-11 object-contain transition-all duration-300 cursor-pointer"
              onClick={onClickLogo}
            />
          </div>

          <div className="flex items-center">
            {/* SearchFilterDisplayボタン */}
            <SC110_C10_SearchFilterDisplay
              areaList={tokyoMunicipalitiesList}
              genreList={genreList}
              isPopupOpen={isPopupOpen}
              togglePopup={togglePopup}
            />

            {/* ハンバーガーメニューボタン */}
            <button
              onClick={toggleMenu}
              className="ml-2 w-8 h-8 md:w-10 md:h-10 flex items-center justify-center text-gray-600 hover:text-pink-500"
            >
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* メニューコンポーネント */}
      <SC110_C11_Menu
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
      />
    </header>
  );
};